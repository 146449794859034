import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Widget } from '@typeform/embed-react'
import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'
import {Helmet} from "react-helmet";


import $ from 'jquery';

class BasesLegales extends Component {

    state = {

    };
    componentDidMount() {

    window.scrollTo(0,0);

}
    render() {

    return (
        <>
            <Helmet>
                <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/barcelona"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Barcelona"/>
                <meta property="og:description" content="El 12 de diciembre, en la segunda parada del Allianz Xuan Lan Yoga Tour, vivimos un sueño, una experiencia donde la música y las vibraciones fluyeron durante nuestro encuentro."/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/barcelona-header.png"></meta>
            </Helmet>
            <Header/>



            <div className="inner-page-banner">
                <img src="./images/Header.png" className="img-fluid" alt=""/>
            </div>


            <div className="about-top-section inner-top-section">
                <div className="container">
                    <div className="title-section text-center">
                        <div className="section-title section-title-blue section-title-normal">
                            <h2><b>Bases legales</b></h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bs-text-section inner-top-section">
                <div className="container">
                    <div className="bs-text-inner">
                        <div className="content-text">
                            <h3>BASES DEL SORTEO DE MEET & GREET PRESENCIAL PARA CONOCER A XUAN LUAN EN Ibiza</h3>


                            <p>1.- EMPRESA ORGANIZADORA Y OBJETO DEL SORTEO
                                <br/>
                                La participación en el “Meet & Greet para conocer a Xuan Lan” implica el conocimiento y la aceptación de la totalidad de los términos y condiciones de este documento (en adelante, las “Bases”) por el participante.
                                <br/>
                                La organización de este evento corre a cargo de Allianz, Compañía de Seguros y Reaseguros, S.A., con CIF A28007748, y domicilio en C/ Ramírez de Arellano, 35, 28043 Madrid, (en adelante el “Organizador”).
                                </p>
                                <br/>

                            <p>
                                
                                2.- PARTICIPANTES Y TERRITORIO
                                <br/>
                                Podrá participar en este Sorteo, conforme a lo indicado en estas Bases, toda persona mayor de 18 años (en adelante el “Participante” o los “Participantes”) y que adquiera una entrada para el Allianz XLY Tour, en El Corte Inglés, mediante la cumplimentación de los datos solicitados en el formulario de participación en el sorteo.
                                {/* <a href='http://www.allianzaxuanlanyogatour.com/' target='_blank' rel='noreferrer'>http://www.allianzaxuanlanyogatour.com/</a> */}
                                 </p>

                            <p>El sorteo es de ámbito nacional.
                                <br/>
                                3.- DURACIÓN
                                <br/>
                                Se podrá participar en el sorteo desde el 8 de mayo al 23 de mayo del 2022, en el siguiente horario:
                                <br/>
                                Inicio:  9 de mayo a las 11:00h.
                                <br/>
                                Final: 23 de mayo a las 23:59h.</p>

                            <p>4.- DINÁMICA
                                <br/>
                                El sorteo se realizará, en las fechas, horario y lugar indicados. En el momento de adquirir una entrada para el Allianz XLY Tour, a través de El Corte Inglés, y habiendo cumplimentado el correspondiente formulario de compra, entrará directamente a formar parte del sorteo de 4 pases Meet&Greet para conocer a Xuan Lan, antes del evento de Ibiza el 28 de mayo. </p>

                            <p>5.- CONDICIONES DEL SORTEO Y PREMIO
                                <br/>
                                Condiciones del sorteo:
                                <br/>
                                El sorteo se realizará a través de AppSorteos,  en el que se seleccionarán a los 4 ganadores del sorteo offline.
                                <br/>
                                El sorteo se realizará el día 24 de mayo y se procederá a comunicar, ese día, a los ganadores su premio.
                                <br/>
                                La empresa organizadora contactará con el ganador a través del correo electrónico que nos facilite, a fin de solicitar sus datos personales y poder identificarlo correctamente a la hora de hacer efectivo su premio. Si el ganador no reclama su premio antes de las 24h. del día 25 de mayo de 2022,  perderá su premio y se volverá a llevar a cabo el sorteo.
                                <br/>
                                La forma de hacer efectivo el premio lo marcará el organizador una vez contacte con el ganador. 
                                <br/>
                                Sólo habrá cuatro (4) ganadores. Si un titular adquiere 5 entradas en el mismo proceso de compra será uno de los ganadores directos del meet&greet, pero solo el titular que realiza la compra.
                                <br/>
                                Queda prohibida la participación de las empresas y/o personas que hayan colaborado directa o indirectamente en el diseño, desarrollo y ejecución de la presente Actividad, así como aquellas personas relacionadas con la organización de la actividad.
                                </p>

                            <p>Premio.
                                <br/>
                                El ganador del sorteo obtendrá como premio un Meet & Greet para conocer a Xuan Lan antes del evento de Ibiza, el 28 de mayo en Aiyanna Ibiza. </p>
                                <br/>
                            <p>El premio no es canjeable por dinero en efectivo, ni será admisible otro tipo de cambio, alteración o compensación, ni cesión a terceros a petición del ganador. En caso de no usar el regalo no se abonará cambio alguno en efectivo al Participante.</p>
                                <br/>

                            <p>Aspectos fiscales

                                <br/>
                                De acuerdo con el artículo 75.3.f del RIRPF, no están sujetos a retención o ingreso a cuenta los premios que se entreguen como consecuencia de la participación en juegos, concursos, rifas o combinaciones aleatorias, estén o no vinculados a la oferta, promoción o venta de determinados bienes, productos o servicios cuya base de retención (cuantía) no exceda de 300 euros.
                                <br/>
                                </p>
                            <p>6.- PROTECCIÓN DE DATOS PERSONALES
                                <br/>
                                El organizador, como Responsable del Tratamiento, y en cumplimiento de lo dispuesto en el Reglamento General (UE) 2016/679 de protección de datos (RGPD) y L.O. 3/2018, de protección de datos y garantía de los derechos digitales (LOPDGDD), le informa que sus datos personales y de contacto, datos familiares en caso de menores, e imagen en el caso de ser ganador de alguno de los premios, serán tratados de acuerdo con la normativa indicada.</p>

                            <p>En el caso de resultar ganador sus datos también servirán para la tramitación y comunicación del premio, y para la gestión de la publicidad de dichos resultados. Esta publicidad podrá realizarse a través de nuestras redes sociales y siempre cumpliendo con lo dispuesto en la Ley Orgánica 1/1982, de 5 de mayo, de protección civil del derecho al honor, a la intimidad personal y familiar, y a la propia imagen, así como a la Ley Orgánica 1/1996, de 15 de enero, de Protección Jurídica del Menor.  </p>

                            <p>Los datos serán conservados únicamente para la participación en este sorteo salvo que nos consienta expresamente a que podamos utilizar dicha información con fines comerciales y/o promocionales en cuyo caso se conservarán indefinidamente, por interés legítimo, o hasta que usted solicita la baja o supresión de sus datos.</p>

                            <p>
                            Usted podrá, en todo momento, ejercitar los derechos de acceso, rectificación, supresión, portabilidad, oposición y limitación al tratamiento enviando comunicación, motivada y acreditada, a la siguiente dirección: lopd@allianz.es
                            </p>

                            <p>
                            Con la aceptación de las bases y envío del formulario Usted, o en el caso de menores el padre/madre/tutor, reconoce haber sido informado claramente sobre el uso de sus datos personales y consiente inequívocamente al tratamiento de los mismos.
                            </p>

                            <p>Le informamos que los datos personales solicitados de cada participante tendrán carácter obligatorio para la participación en el sorteo y gestión del premio.</p>
                            
                            <p>7.- ACEPTACIÓN DE LAS BASES, MODIFICACIÓN Y RECLAMACIÓN
                                <br/>
                                La participación en este sorteo implica la aceptación de todos y cada uno de los términos y condiciones incluidas en las presentes bases legales.</p>

                            <p>
                            Excepcionalmente, y por motivos debidamente justificados, se podrán modificar las presentes bases una vez comenzada, siempre que dicha modificación no perjudique a los participantes y se les comunique debidamente la modificación efectuada.
                            </p>
                            <p>
                            Cualquier manifestación en sentido contrario a la aceptación de las Bases, supondrá la exclusión del participante y, desde ese momento, el organizador quedará libre de cualquier obligación contraída con dicho participante.
                            </p>

                            

                            <p>8.- EXCLUSIONES Y ELIMINACIONES
                                <br/>
                                El organizador se reserva el derecho de eliminar a cualquier participante que trate de, o llegue a defraudar, alterar o inutilizar el buen funcionamiento y el transcurso normal y reglamentario del presente sorteo.</p>

                            <p>En el caso de detectar cualquier participación que pueda lesionar los derechos del resto de participantes, así como de menores, se reserva el derecho de eliminar estas participaciones.</p>
                           
                            <p>9.- RESPONSABILIDAD DE LA ORGANIZADORA
                                <br/>
                                El organizador no se responsabiliza de cualquier circunstancia imputable a terceros que puedan afectar al desarrollo del sorteo y los premios de la misma.</p>

                            <p>El organizador tampoco responderá de los casos de fuerza mayor, o de los casos ajenos a su control, que pudieran impedir al ganador el disfrute de su premio, quedando exenta de toda responsabilidad si concurriese alguno de los casos señalados.</p>
                            <p>En caso de que el sorteo no pudiera realizarse, bien por fraudes detectados en la misma, errores técnicos, o cualquier otro motivo que no esté bajo el control y que afecte al normal desarrollo del sorteo, el organizador se reserva el derecho a cancelar, modificar, y/o suspender la misma.</p>
                            <p>Producida la entrega en los términos indicados, se entienden cumplidas por el organizador, las obligaciones asumidas en virtud de este sorteo, renunciando el premiado a cualquier reclamación posterior.</p>

                            <p>10.- MODIFICACIONES Y/O ANEXOS 
                                <br/>
                                El organizador se reserva el derecho de modificar, cambiar, ampliar e introducir anexos referidos las condiciones del presente sorteo en cualquier momento e incluso su posible anulación, antes de la fecha de cierre del sorteo, siempre que hubiera causa justificada, comprometiéndose a notificar con suficiente antelación las nuevas bases, condiciones, o en su caso la anulación definitiva, a través de los mismos medios en que se han comunicado las bases.
                                </p>

                            <p>11.- LEY APLICABLE Y JURISDICCIÓN
                                <br/>
                                Las bases del presente sorteo se regirán por lo dispuesto en la legislación española. Sin perjuicio de lo dispuesto respecto a la solución de incidencias por participación fraudulenta, para  resolución de cualquier conflicto en la interpretación y/o aplicación de las presentes bases, tanto el organizador, como los participantes, se someten expresamente, con renuncia a otro fuero que pudiera corresponderles, a la jurisdicción y competencia de los Juzgados y Tribunales de Madrid Capital.</p>
                        </div>




                        <div className="content-text">
                            <h3>BASES DEL SORTEO DE MEET & GREET ONLINE PARA CONOCER A XUAN LUAN</h3>
                            <p>1.- EMPRESA ORGANIZADORA Y OBJETO DEL SORTEO
                                <br/>
                                La participación en el “Meet&Greet para conocer a Xuan Lan” implica el conocimiento y la aceptación de la totalidad de los términos y condiciones de este documento (en adelante, las “Bases”) por el participante.</p>
                                <br/>
                            <p>La organización de este evento corre a cargo de Allianz, Compañía de Seguros y Reaseguros, S.A., con CIF A28007748, y domicilio en calle Ramírez de Arellano, 35, 28043 Madrid, (en adelante el “Organizador”).
                                <br/>
                                Reaseguros, S.A., con CIF A28007748, y domicilio en calle Ramírez de Arellano, 35, 28043 Madrid, (en adelante el “Organizador”).
                                <br/>
                                2.- PARTICIPANTES Y TERRITORIO
                                <br/>
                                Podrá participar en este Sorteo, conforme a lo indicado en estas Bases, toda persona mayor de 18 años (en adelante el “Participante” o los “Participantes”) y que se inscriba al streaming online y cumplimente los datos solicitados en el formulario gratuito de inscripción.
 
                                <br/>
                            </p>
                            <p>El sorteo es de ámbito mundial.
                                <br/>
                                3.- DURACIÓN
                                <br/>
                                Se podrá participar en el sorteo desde el 9 de mayo al 23 de mayo de 2022, en el siguiente horario:

                                <br/>
                                Inicio: 9 de mayo, a las 11:00h.
                                <br/>
                                Final: 23 mayo, a las 23:59h.
                            </p>
                            <p>4.- DINÁMICA
                                <br/>
                                El sorteo se realizará el 24 de mayo del 2022 y consistirá en un premio de diez pases online meet&greet para conocer a Xuan Lan, el día 28 de mayo a las 17:00h hora española.
                            </p>
                            <p>5.- CONDICIONES DEL SORTEO Y PREMIO
                                <br/>Condiciones del sorteo: 
                                <br/>
                                El sorteo se realizará a través de app-sorteos, en el que se seleccionarán a los 10 ganadores.
                                
                                <br/>
                                El sorteo se realizará el día 24 de mayo y se procederá a comunicar, ese día, a los ganadores su premio.

                                <br/>
                                La empresa organizadora contactará con el ganador a través del correo electrónico que nos facilite, a fin de solicitar sus datos personales y poder identificarlo correctamente para hacer efectivo su premio. Si el ganador no reclama su premio antes de las 24h. del día 24 perderá su premio.
                                <br/>
                                La forma de hacer efectivo el premio se comunicará, junto al link de la conexión del meet&greet, por mail. 
                                <br/>
                                Sólo habrá diez (10) ganadores. 
                                <br/>
                                Queda prohibida la participación de empresas y/o personas que hayan colaborado directa o indirectamente en el diseño, desarrollo y ejecución de la presente Actividad, así como aquellas personas relacionadas con la organización de la actividad. 
                                <br/>
                                </p>
                            <p>Premio.
                                <br/>
                                El ganador del sorteo obtendrá como premio un meet & greet online para conocer a Xuan Lan. </p>
                            <p>El premio no es canjeable por dinero en efectivo, ni será admisible otro tipo de cambio, alteración o compensación, ni cesión a terceros a petición del ganador. En caso de no usar el regalo no se abonará cambio alguno en efectivo al Participante.</p>
                            <p>Aspectos fiscales
                                <br/>
                                De acuerdo con el artículo 75.3.f del RIRPF, no están sujetos a retención o ingreso a cuenta los premios que se entreguen como consecuencia de la participación en juegos, concursos, rifas o combinaciones aleatorias, estén o no vinculados a la oferta, promoción o venta de determinados bienes, productos o servicios cuya base de retención (cuantía) no exceda de 300 euros. </p>
                            <p>6.- PROTECCIÓN DE DATOS PERSONALES
                                <br/>
                                El organizador, como Responsable del Tratamiento, y en cumplimiento de lo dispuesto en el Reglamento General (UE) 2016/679 de protección de datos (RGPD) y L.O. 3/2018, de protección de datos y garantía de los derechos digitales (LOPDGDD), le informa que sus datos personales y de contacto, datos familiares en caso de menores, e imagen en el caso de ser ganador de alguno de los premios, serán tratados de acuerdo con la normativa indicada.</p>
                            <p>En el caso de resultar ganador sus datos también servirán para la tramitación y comunicación del premio, y para la gestión de la publicidad de dichos resultados. Esta publicidad podrá realizarse a través de nuestras redes sociales mediante la utilización de su imagen, que se tratará de acuerdo con la Ley Orgánica 1/1982, de 5 de mayo, de protección civil del derecho al honor, a la intimidad personal y familiar, y a la propia imagen, así como a la Ley Orgánica 1/1996, de 15 de enero, de Protección Jurídica del Menor. Estas imágenes no serán utilizadas para ningún otro fin ni cedidas a terceros no autorizados. </p>
                            <p>Los datos serán conservados únicamente para la participación en este sorteo salvo que nos consienta expresamente a que podamos utilizar dicha información con fines comerciales y/o promocionales en cuyo caso se conservarán indefinidamente, por interés legítimo, o hasta que usted solicita la baja o supresión de sus datos.</p>
                            <p>Usted podrá, en todo momento, ejercitar los derechos de acceso, rectificación, supresión, portabilidad, oposición y limitación al tratamiento enviando comunicación, motivada y acreditada, a la siguiente dirección: lopd@allianz.es </p>
                            <p>Con la aceptación de las bases y envío del formulario Usted, o en el caso de menores el padre/madre/tutor, reconoce haber sido informado claramente sobre el uso de sus datos personales y consiente inequívocamente al tratamiento de los mismos. </p>
                            <p>La informamos que los datos personales solicitados de cada participante tendrán carácter obligatorio para la participación en el sorteo y gestión del premio.</p>
                            <p>7.- DERECHOS DE IMAGEN 
                                <br/>
                                Los participantes consienten automáticamente, al participar en el presente sorteo, en la cesión, utilización, publicación y reproducción en todo el mundo y sin limitación, a y por parte del organizador, de su imagen y nombre en cualquier tipo de difusión, sorteo, publicación, incluido Internet, redes sociales, o cualquier otro medio de la naturaleza que sea, con fines informativos siempre que éstos se relacionen con el presente sorteo, sin que ello genere derecho a favor de los participantes a recibir contraprestación alguna por ello. </p>
                            <p>Particularmente consienten en la publicación y difusión gratuita de un número indeterminado de fotografías y vídeos de su imagen como ganadores del sorteo, para acompañar la publicación de artículos de prensa escrita, radio, televisión e internet, incluyendo redes sociales y en la página web de Allianz y Xuan Lan.</p>
                            <p>8.- ACEPTACIÓN DE LAS BASES, MODIFICACIÓN Y RECLAMACIÓN
                                <br/>
                                La participación en este sorteo implica la aceptación de todos y cada uno de los términos y condiciones incluidas en las presentes bases legales.</p>
                            <p>Excepcionalmente, y por motivos debidamente justificados, se podrán modificar las presentes bases una vez comenzada, siempre que dicha modificación no perjudique a los Participantes y se les comunique debidamente la modificación efectuada. </p>
                            <p>Cualquier manifestación en sentido contrario a la aceptación de las Bases, supondrá la exclusión del Participante y, desde ese momento, el organizador quedará libre de cualquier obligación contraída con dicho Participante. </p>
                            <p>9.- EXCLUSIONES Y ELIMINACIONES
                                <br/>
                                El organizador se reserva el derecho de eliminar a cualquier Participante que trate de, o llegue a defraudar, alterar o inutilizar el buen funcionamiento y el transcurso normal y reglamentario del presente sorteo. </p>
                            <p>En el caso de detectar cualquier participación que pueda lesionar los derechos del resto de Participantes, así como de menores, se reserva el derecho de eliminar estas participaciones.</p>
                            <p>10.- RESPONSABILIDAD DE LA ORGANIZADORA
                                <br/>
                                El organizador no se responsabiliza de cualquier circunstancia imputable a terceros que puedan afectar al desarrollo del sorteo y los premios de la misma. </p>
                            <p>El organizador tampoco responderá de los casos de fuerza mayor, o de los casos ajenos a su control, que pudieran impedir al ganador el disfrute de su premio, quedando exenta de toda responsabilidad si concurriese alguno de los casos señalados.</p>
                            <p>En caso de que el sorteo no pudiera realizarse, bien por fraudes detectados en la misma, errores técnicos, o cualquier otro motivo que no esté bajo el control y que afecte al normal desarrollo del sorteo, el organizador se reserva el derecho a cancelar, modificar, y/o suspender la misma.</p>
                            <p>Producida la entrega del premio se entenderá cumplida por ALLIANZ XLY TOUR las obligaciones asumidas en virtud de este sorteo, renunciando el premiado a cualquier reclamación posterior. </p>
                            <p>11.- MODIFICACIONES Y/O ANEXOS
                                <br/>
                                El organizador se reserva el derecho de modificar, cambiar, ampliar e introducir anexos referidos las condiciones del presente sorteo en cualquier momento e incluso su posible anulación, antes de la fecha de cierre del sorteo, siempre que hubiera causa justificada, comprometiéndose a notificar con suficiente antelación las nuevas bases, condiciones, o en su caso la anulación definitiva, a través de los mismos medios en que se han comunicado las bases. </p>
                            <p>12.- LEY APLICABLE Y JURISDICCIÓN
                                <br/>
                                Las bases del presente sorteo se regirán por lo dispuesto en la legislación española. Sin perjuicio de lo dispuesto respecto a la solución de incidencias por participación fraudulenta, para resolución de cualquier conflicto en la interpretación y/o aplicación de las presentes bases, tanto el organizador, como los participantes, se someten expresamente, con renuncia a otro fuero que pudiera corresponderles, a la jurisdicción y competencia de los Juzgados y Tribunales de Madrid Capital.</p>
                        </div>

                        <div className="content-text">
                            <h3>BASES DEL SORTEO Ibiza</h3>
                            <p>1.- EMPRESA ORGANIZADORA Y OBJETO DEL SORTEO 
                                <br/>
                                La participación en el “Sorteo entrada doble evento Ibiza + alojamiento + desplazamiento” implica el conocimiento y la aceptación de la totalidad de los términos y condiciones de este documento (en adelante, las “Bases”) por el participante. </p>
                                <br/>
                            <p>La organización de este evento corre a cargo de Allianz, Compañía de Seguros y 
                                <br/>
                                Reaseguros, S.A., con CIF A28007748, y domicilio en C/ Ramírez de Arellano, 35, 28043 Madrid, (en adelante el “Organizador”). 
                                <br/>
                                2.- PARTICIPANTES Y TERRITORIO 
                                <br/>
                                Podrá participar en este Sorteo, conforme a lo indicado en estas Bases, toda persona mayor de 18 años (en adelante el “Participante” o los “Participantes”) y que cumplimente el formulario online alojado en la web http://www.allianzaxuanlanyogatour.com/ habilitado para participar en este sorteo.
                                <br/>
                            </p>
                            <p>El sorteo es de ámbito nacional.
                                <br/>
                                3.- DURACIÓN 
                                <br/>
                                Se podrá participar en el sorteo desde el 13 de octubre al 15 de mayo de 2022, en el siguiente horario: 
                                <br/>
                                Inicio: 13 de octubre 2021, a las 11:00h. 
                                <br/>
                                Final: hasta 15 días hábiles previos al evento de Ibiza en 2022. 
                            </p>
                            <p>4.- DINÁMICA 
                                <br/>
                                El sorteo se realizará, en las fechas, horario y lugar indicados, tras haber rellenado el formulario online alojado en la web www.allianzaxuanlanyogatour.com habilitado específicamente para la participación en este sorteo, contestando y rellenando las preguntas e información necesaria.
                            </p>
                            <p>5.- CONDICIONES DEL SORTEO Y PREMIO 
                                <br/>
                                Condiciones del sorteo:  
                                <br/>
                                El sorteo se realizará a través de AppSorteos, en el que se seleccionará al ganador. Sólo habrá 1 ganador.  
                                <br/>
                                El sorteo se realizará 14 días hábiles previos al evento de Ibiza en 2022. 
                                <br/>
                                El día 16 de mayo se procederá a comunicar el premio a través del correo electrónico facilitado en el sorteo.
                                <br/>
                                Si el ganador no reclama su premio antes de las 24h. de la fecha en la que se le comunique, perderá su premio. 
                                <br/>
                                La forma de hacer efectivo el premio lo marcará ALLIANZ XLY TOUR una vez contacte con el ganador. 
                                <br/>
                                Queda prohibida la participación de empresas y/o personas que hayan colaborado directa o indirectamente en el diseño, desarrollo y ejecución de la presente Actividad, así como aquellas personas relacionadas con la organización de la actividad. 
                                <br/>
                                </p>
                            <p>Premio. 
                                <br/>
                                El ganador del sorteo obtendrá como premio dos entradas para el evento Allianz Xuan Lan Yoga Tour de Ibiza , alojamiento de 1 noche, para dos personas, vuelos y desplazamientos. </p>
                            <p>El premio no es canjeable por dinero en efectivo, ni será admisible otro tipo de cambio, alteración o compensación, ni cesión a terceros a petición del ganador. En caso de no usar el regalo no se abonará cambio alguno en efectivo al Participante. 
</p>
                            <p>Aspectos fiscales
                                <br/>
                                De acuerdo con el artículo 75.3.f del RIRPF, no están sujetos a retención o ingreso a cuenta los premios que se entreguen como consecuencia de la participación en juegos, concursos, rifas o combinaciones aleatorias, estén o no vinculados a la oferta, promoción o venta de determinados bienes, productos o servicios cuya base de retención (cuantía) no exceda de 300 euros.  </p>
                                <p>De conformidad con la citada normativa, se informa a los participantes, que el Premio concedido por la participación en juegos, concursos, rifas, o combinaciones aleatorias vinculadas a la venta o promoción de bienes o servicios tiene la consideración de ganancia patrimonial. </p>
                                
                            <p>6.- PROTECCIÓN DE DATOS PERSONALES
                                <br/>
                                El organizador, como Responsable del Tratamiento, y en cumplimiento de lo dispuesto en el Reglamento General (UE) 2016/679 de protección de datos (RGPD) y L.O. 3/2018, de protección de datos y garantía de los derechos digitales (LOPDGDD), le informa que sus datos personales y de contacto, datos familiares en caso de menores, e imagen en el caso de ser ganador de alguno de los premios, serán tratados de acuerdo con la normativa indicada. </p>
                            <p>En el caso de resultar ganador sus datos también servirán para la tramitación y comunicación del premio, y para la gestión de la publicidad de dichos resultados. Esta publicidad podrá realizarse a través de nuestras redes sociales mediante la utilización de su imagen, que se tratará de acuerdo con la Ley Orgánica 1/1982, de 5 de mayo, de protección civil del derecho al honor, a la intimidad personal y familiar, y a la propia imagen, así como a la Ley Orgánica 1/1996, de 15 de enero, de Protección Jurídica del Menor. Estas imágenes no serán utilizadas para ningún otro fin ni cedidas a terceros no autorizados. </p>
                            <p>Los datos serán conservados únicamente para la participación en este sorteo salvo que nos consienta expresamente a que podamos utilizar dicha información con fines comerciales y/o promocionales en cuyo caso se conservarán indefinidamente, por interés legítimo, o hasta que usted solicita la baja o supresión de sus datos.</p>
                            <p>Usted podrá, en todo momento, ejercitar los derechos de acceso, rectificación, supresión, portabilidad, oposición y limitación al tratamiento enviando comunicación, motivada y acreditada, a la siguiente dirección: lopd@allianz.es</p>
                            <p>
                            Con la aceptación de las bases y envío del formulario Usted, o en el caso de menores el padre/madre/tutor, reconoce haber sido informado claramente sobre el uso de sus datos personales y consiente inequívocamente al tratamiento de los mismos. </p>
                            <p>Le informamos que los datos personales solicitados de cada participante tendrán carácter obligatorio para la participación en el sorteo y gestión del premio. </p>
                            <p>7.- DERECHOS DE IMAGEN 
                                <br/>
                                Los participantes consienten automáticamente, al participar en el presente sorteo, en la cesión, utilización, publicación y reproducción en todo el mundo y sin limitación, a y por parte del organizador, de su imagen y nombre en cualquier tipo de difusión, sorteo, publicación, incluido Internet, redes sociales, o cualquier otro medio de la naturaleza que sea, con fines informativos siempre que éstos se relacionen con el presente sorteo, sin que ello genere derecho a favor de los participantes a recibir contraprestación alguna por ello.
 </p>
                            <p>Particularmente consienten en la publicación y difusión gratuita de un número indeterminado de fotografías y vídeos de su imagen como ganadores del sorteo, para acompañar la publicación de artículos de prensa escrita, radio, televisión e internet, incluyendo redes sociales y en la página web de Allianz y Xuan Lan.
</p>
                            <p>8.- ACEPTACIÓN DE LAS BASES, MODIFICACIÓN Y RECLAMACIÓN
                                <br/>
                                La participación en este sorteo implica la aceptación de todos y cada uno de los términos y condiciones incluidas en las presentes bases legales.  </p>
                            <p>Excepcionalmente, y por motivos debidamente justificados, se podrán modificar las presentes bases una vez comenzada, siempre que dicha modificación no perjudique a los Participantes y se les comunique debidamente la modificación efectuada.</p>
                            <p>Cualquier manifestación en sentido contrario a la aceptación de las Bases, supondrá la exclusión del Participante y, desde ese momento, el organizador quedará libre de cualquier obligación contraída con dicho Participante.</p>
                            <p>9.- EXCLUSIONES Y ELIMINACIONES 
                                <br/>
                                El organizador se reserva el derecho de eliminar a cualquier Participante que trate de, o llegue a defraudar, alterar o inutilizar el buen funcionamiento y el transcurso normal y reglamentario del presente sorteo. </p>
                                <p>En el caso de detectar cualquier participación que pueda lesionar los derechos del resto de Participantes, así como de menores, se reserva el derecho de eliminar estas participaciones.</p>
                            <p>10.- RESPONSABILIDAD DE LA ORGANIZADORA
                                <br/>El organizador no se responsabiliza de cualquier circunstancia imputable a terceros que puedan afectar al desarrollo del sorteo y los premios de la misma. </p>
                            <p>El organizador tampoco responderá de los casos de fuerza mayor, o de los casos ajenos a su control, que pudieran impedir al ganador el disfrute de su premio, quedando exenta de toda responsabilidad si concurriese alguno de los casos señalados. </p>
                            <p>En caso de que el sorteo no pudiera realizarse, bien por fraudes detectados en la misma, errores técnicos, o cualquier otro motivo que no esté bajo el control y que afecte al normal desarrollo del sorteo, el organizador se reserva el derecho a cancelar, modificar, y/o suspender la misma.</p>
                            <p>Producida la entrega del premio se entenderá cumplida por ALLIANZ XLY TOUR las obligaciones asumidas en virtud de este sorteo, renunciando el premiado a cualquier reclamación posterior.</p>
                            <p>11.- MODIFICACIONES Y/O ANEXOS 
                                <br/>
                                El organizador se reserva el derecho de modificar, cambiar, ampliar e introducir anexos referidos las condiciones del presente sorteo en cualquier momento e incluso su posible anulación, antes de la fecha de cierre del sorteo, siempre que hubiera causa justificada, comprometiéndose a notificar con suficiente antelación las nuevas bases, condiciones, o en su caso la anulación definitiva, a través de los mismos medios en que se han comunicado las bases.</p>
                            <p>12.- LEY APLICABLE Y JURISDICCIÓN
                                <br/>
                                Las bases del presente sorteo se regirán por lo dispuesto en la legislación española. Sin perjuicio de lo dispuesto respecto a la solución de incidencias por participación fraudulenta, para  resolución de cualquier conflicto en la interpretación y/o aplicación de las presentes bases, tanto el organizador, como los participantes, se someten expresamente, con renuncia a otro fuero que pudiera corresponderles, a la jurisdicción y competencia de los Juzgados y Tribunales de Madrid Capital. </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
</>
)
}

}

export default BasesLegales;