import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Link as ReactLink } from 'react-router-dom';
import '../css/modal-video.css';
import ModalVideo from 'react-modal-video';
import Zoom from 'react-medium-image-zoom'
import {Helmet} from "react-helmet";
import 'react-medium-image-zoom/dist/styles.css'
// import bannerLogo from '../images/logo-banner-2.png'; Example of image used


import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Ibiza extends Component {

    state = {
        isOpen: false,
        isOpenBig: false
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    openModal = () => {
        this.setState({isOpen: true})
      }
    openModalBig = () => {
        this.setState({isOpenBig: true})
      }
    render() {
       
        return (

            <>
            
                            {/* <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/sevilla"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Sevilla"/>
                <meta property="og:description" content="En esta parada queremos invitarte a sentirte parte de una comunidad que  comparte una energía positiva con la práctica de yoga."/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/sevilla-header.png"></meta> */}
            <Helmet>

                <title>Allianz Xuan Lan Yoga Tour | Seville</title>
                <meta name="description" content="At this stop we want to invite you to feel part of a community that shares positive energy with the practice of yoga." />
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/sevilla/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Seville" />
                <meta property="og:description" content="At this stop we want to invite you to feel part of a community that shares positive energy with the practice of yoga." />
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/sevilla-header.png" />
                <meta property="twitter:domain" content="allianzxuanlanyogatour.com" />
                <meta property="twitter:url" content="https://www.allianzxuanlanyogatour.com/sevilla/" />
                <meta name="twitter:title" content="Allianz Xuan Lan Yoga Tour | Seville" />
                <meta name="twitter:description" content="At this stop we want to invite you to feel part of a community that shares positive energy with the practice of yoga." />
                <meta name="twitter:image" content="https://www.allianzxuanlanyogatour.com/images/sevilla-header.png"></meta>
            </Helmet>
            <Header/>
                <div className="inner-page-banner">
                    <img src="./images/ibzia-header.png" className="img-fluid" alt=""/>
                </div>


                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title">
                                <h2><b>Ibiza</b>
                                    <br/>
                                <span className="title-left-space color-dark">la armonía con la naturaleza</span></h2> 
                                 <p className="text-center">
                                En nuestro día a día, estamos acostumbrados a dar y recibir amor de nuestra familia, amigos y gente que nos rodea. De la misma forma, la naturaleza nos da de manera altruista un amor incondicional que influye positivamente en nuestro bienestar y salud integral y que nosotr@s tenemos que cuidar y devolver.
                                <br/>
                                <br/>
                                En esta 4ª y última parada del Allianz Xuan Lan Yoga Tour, Ibiza será el lugar ideal para redescubrir esta fuerte conexión con la naturaleza, sentir una relación cercana y un vínculo emocional con nuestro entorno natural.
                                <br/>
                                <br/>
                                Proteger el medio ambiente es una necesidad para mantenernos emocional, psicológica y físicamente saludables y conectar con nuestro universo.
                                <br/>
                                <br/>
                                Queremos vivir contigo una experiencia única en conexión con la naturaleza y descubrir junt@s los beneficios del mundo natural para nuestra salud y bienestar. 
                                </p>
                                <br/>
                                <br/>
                               
                            </div>
                        </div>
                        {/* <div className="col-lg-12 order-lg-1" style={{display:"none"}}>
                                <div className="btn-post" style={{textAlign:"center"}}>
                                    <a href="https://www.allianzxuanlanyogatour.com/inscripcion" className="btn btn-blue"><span className="text-light">Inscribirme</span></a>
                                </div>
                            </div> */}
                    </div>
                </div>
                <div className="about-top-section inner-top-section pt-0 madrid-big-video">
                    <div className="container hover-cursor" onClick={this.openModalBig}>
                        <img src="./images/ibzia-video-big.png" alt="madrid video"/>
                    </div>
                </div>

{/* 
                <div className="content-section content-img-left inr-content-section inr-content-section-1">
                    <div className="container">
                        <div className="section-title text-center mb-5">
                            <h3>Lugar y fecha del evento<br/></h3>
                        </div>
                        <div className="row inr-content-outer mt-0">
                            <div className="col-lg-6">
                                <div className="content-left" style={{paddingRight:"0px"}}>
                                    <div className="content-img content-img-madrid">
                                        <img src="./images/ibzia-left.png" className="img-fluid pic-Ibiza" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right">
                                    <div className="content-text mt-0">
                                        <div className="content-title content-title-sevilla">
                                            <h3 className="color-blue Ibiza-head-right">
                                                Aiyanna Ibiza 
                                            <br/>
                                                28 de mayo | 19:30h</h3>
                                        </div>
                                        <div className="content-desc content-desc-Ibzia">
                                            <p>La magia de la isla de Ibiza y sus atardeceres serán los protagonistas de esta última parada del Allianz Xuan Lan Yoga Tour. Disfruta de una maravillosa experiencia en Aiyanna Ibiza, un espacio en armonía con la naturaleza ubicado al norte de la isla en la bahía de Cala Nova.  </p>
                                        </div>
                                        <div className="content-title content-title-sevilla">
                                            <h3 className="color-blue Ibiza-head-right">Afterparty</h3>
                                        </div>
                                        <div className="content-desc content-desc-Ibiza">
                                            <p>¡Después de la masterclass, la fiesta continúa! Hemos preparado un afterparty muy especial apra celebrar junt@s el final de este #AllianzXLY tour. DJ en directo, cena y muchas sopresas más. ¡No te lo puedes perder!</p>
                                        </div>
                                        <div className="btn-post mt-2">
                                            
                                            <ReactLink to="/inscripcion" className="btn btn-blue"><span className='btn-blue'>Inscribirme</span></ReactLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offset-2 col-8">
                            <div className="content-desc mt-5">
                                <p className="text-center color-blue">Con la compra de la  entrada presencial, participas en el sorteo de 1 pase <ReactLink to="/bases-legales" className='color-blue'>Meet & Greet*</ReactLink> para conocer a Xuan Lan en el evento de Ibiza. Sorteamos 4 pases en total, ¡te deseamos mucha suerte!
                                </p>
                            </div>
                        </div>
                </div> */}

                <div className="content-section content-img-right inr-content-section inr-content-section-2">
                    <div className="container">
                        <div className="section-title text-center">
                            <h3>Invitado especial</h3>
                        </div>
                        <div className="row inr-content-outer mt-5">
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="content-left content-left-Ibiza">
                                    <div className="content-text content-text-ibz">
                                        <div className="content-title">
                                            <h3>Manu San Félix</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>Explorador de National Geographic y biólogo marino, Manu San Felix, es un reconocido reportero gráfico que ha dedicado su vida a la investigación y cuidado del mundo natural, concretamente el entorno submarino. Lleva más de diez años en el equipo de “Pristine Seas”, un proyecto de National Geographic que se dedica a investigar, difundir y concienciar para la conservación de nuestro planeta.<br/>
                                            En esta última parada nos acompañará para conocer más sobre su labor naturalista y la importancia de cuidar y respetar nuestro planeta como una más parte de nosotros mismos. 
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0">
                                {/* <div className="content-right hover-cursor" onClick={this.openModal}> */}
                                <div className="content-right hover-cursor">
                                    <div className="content-img content-img-sevilla content-img-Ibiza1">
                                        <img src="./images/Manu-San.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-content text-center">
                            <ReactLink to="inscripcion" className="btn btn-blue btn-disabled"><span>Entrevista completa</span></ReactLink>
                        </div>
                        {/* <div className="small-text text-center custom-width">
                            <p><a href="https://consaludmental.org/" target="_blank"> Con la compra de tu entrada colaboras con la Confederación Salud Mental España y además, entras en el sorteo de un pase Meet&Greet presencial para conocer a Xuan Lan el día del evento.</a>
                            </p>
                        </div> */}
                    </div>
                    <div className="mt-5" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">
                                    {/* <div className="title-section text-center">
                                        <div className="section-title section-title-madrid">
                                            <p style={{color:"#000"}}>¿Sabías que la luz y los colores influyen constantemente en nuestra manera de actuar y de sentir? Para dar respuesta a esta y muchas otra preguntas, Xuan Lan entrevista a David del Rosario, científico e investigador especialista en Neurociencias. ¡No te lo puedes perder!</p>
                                        </div>
                                    </div> */}

                                    {/* <div className="btn-post text-center">
                                        <a href="https://www.youtube.com/watch?v=0DexmEK8x84" rel="noreferrer" target="_blank" className="btn btn-blue"><span>Entrevista completa</span></a>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                {/* <div className="post-section" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">

                                    <div className="title-section text-center">
                                        <div className="section-title">
                                            <h2>¿Quieres vivir la experiencia en directo desde donde tú quieras?</h2>
                                            <p>¡Te proponemos una experiencia mágica online! <br/>
                                            Queremos que nos acompañes en este viaje del bienestar y formes parte del Allianz Xuan Lan Yoga tour estés donde estés. <br/>
                                            ¡Únete a nosotros en esta gran aventura!</p>
                                        </div>
                                    </div>

                                    <div className="btn-post text-center mb-5">
                                        <ReactLink to="asistir-online" className="btn btn-blue"><span>Registrarme online</span></ReactLink>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="about-top-section inner-top-section pt-0 madrid-big-video">
                    <div className="container hover-cursor" onClick={this.openModalBig}>
                        <img src="./images/barcelona-big-img.png" alt="madrid video"/>
                    </div>
                </div> */}
                {/* <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title">
                                <h2 style={{marginLeft:"1px"}} className="text-center color-dark">El evento</h2>
                                <p className="text-center">
                                En esta primera parada del #AllianzXLY Madrid descubriremos juntos cómo la armonía de la luz puede guiarnos en nuestro camino hacia el bienestar.
                                    <br/>
                                    <br/>
                                Empezaremos aprendiendo junto a Xuan Lan, en una entrevista con David del Rosario, investigador en neurociencias, a entender nuestra mente desde una perspectiva diferente. Parte de esta entrevista se retransmitirá antes de la masterclass, tanto en el evento presencial como durante el streaming, y se facilitará la entrevista completa a todos los participantes vía mail.
                                    <br/>
                                    <br/>
                                Seguiremos nuestro camino con una masterclass de yoga, una experiencia única y sensorial en donde la armonía de la luz y los colores nos guiarán durante toda la jornada.
                                    <br/>
                                    <br/>
                                Pero esto no termina aquí, muchas más sorpresas estarán esperándote en Madrid. ¿Te lo vas a perder?
                                </p>
                            
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* <div className="content-section content-img-left inr-content-section inr-content-section-1">
                    <div className="container">
                        <div className="section-title text-center mb-5">
                            <h3>Lugar y fecha del evento<br/></h3>
                        </div>
                        <div className="row align-items-center inr-content-outer">
                            <div className="col-lg-6">
                                <div className="content-left">
                                    <div className="content-img">
                                        <img src="./images/sevilla-left.png" className="img-fluid pic" alt="" style={{height:"auto"}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right">
                                    <div className="content-text" style={{marginTop:"0px"}}>
                                        <div className="content-title">
                                            <h3 className="color-blue">Plaza de España de Sevilla
                                            <br/>26 de marzo | Hora: 11:30h</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>Disfruta de una clase de yoga en la plaza más representativa e histórica de España. El punto de encuentro donde el arte y la historia se unen creando un espacio único en torno a la comunidad. </p>
                                        </div>
                                        <div className="content-icon-section">
                                            <p><img src="./images/map-location-pin-1-1@2x.svg" className="img-fluid" alt=""/> Av. Isabel la Católica, 41004 Sevilla</p>
                                             <p><img src="./images/subway-1@2x.svg" className="img-fluid" alt=""/> L3 Estación Drassanes</p> 
                                        </div>
                                        <div className="btn-post">
                                            <a href="https://www.allianzxuanlanyogatour.com/inscripcion" className="btn btn-blue"><span className="text-light">Inscribirme</span></a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="offset-1 col-10">
                            <div className="content-desc mt-5">
                                <p className="text-center">Cualquier novedad o cambio te mantendremos informad@ a través de este canal de comunicación y en las redes sociales @allianz_es y @xuanlanyoga.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* <div className="content-section content-img-right inr-content-section inr-content-section-2">
                    <div className="container">
                        <div className="section-title text-center">
                            <h3>Invitado especial</h3>
                        </div>
                        <div className="row align-items-center inr-content-outer">
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="content-left">
                                    <div className="content-text">
                                        <div className="content-title">
                                            <h3>Mario Alonso Puig </h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>Médico y licenciado en Harvard University Medical School, ha dedicado gran parte de su vida al estudio de la Inteligencia Humana y la psiconeurobiología.<br/>
                                            Lleva más de 20 años impartiendo cursos y conferencias, referente internacional en liderazgo, trabajo en equipo, gestión del cambio, salud, bienestar, felicidad, comunicación, creatividad e innovación.<br/>
                                            Estáte atento@ a nuestras redes sociales. Próximamente abriremos convocatoria a la inscripción del #AllianzXLY Sevilla.</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <div className="content-right hover-cursor" onClick={this.openModal}>
                                    <div className="content-img">
                                        <img style={{height:"auto"}} src="./images/sevilla-right.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div className="btn-content text-center">
                            <ReactLink to="inscripcion" className="btn btn-blue"><span>Inscribirme</span></ReactLink>
                        </div>
                        <div className="small-text text-center custom-width">
                            <p><a href="https://consaludmental.org/" target="_blank"> Con la compra de tu entrada colaboras con la Confederación Salud Mental España y además, entras en el sorteo de un pase Meet&Greet presencial para conocer a Xuan Lan el día del evento.</a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-5" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">
                                    <div className="title-section text-center">
                                        <div className="section-title section-title-madrid">
                                            <p>¿Sabías que posiblemente el universo en el que vivimos se creó a partir de un sonido y vibración? Nuestra invitada The Wong Janice, violonchelista experta en la disciplina de sound healing, una técnica de sanación ancestral, nos mostrará junto a Xuan Lan los beneficios de este arte que ha ayudado a miles de personas transmitiendo el poder tiene la música. ¡No te lo puedes perder!</p>
                                        </div>
                                    </div>

                                    <div className="btn-post text-center">
                                        <a href="https://www.youtube.com/watch?v=J59LEIY2SpY&t=1s" rel="noreferrer" target="_blank" className="btn btn-blue"><span>Entrevista completa</span></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div> */} 
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='IYQNcb6roP0' onClose={() => this.setState({isOpen: false})} autoplay={true}/>
                <ModalVideo channel='youtube' isOpen={this.state.isOpenBig} videoId='0y4_o9KN8aI' onClose={() => this.setState({isOpenBig: false})} autoplay={true}/>
            <Footer/>
            </>
        )
    }

}




export default Ibiza;