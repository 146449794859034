import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
// import bannerLogo from '../images/logo-banner-2.png'; Example of image used
import {Helmet} from "react-helmet";
import { NavLink as ReactLink } from 'react-router-dom';
import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Promociones extends Component {

    state = {
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    render() {
       
        return (
            <>
            <Helmet>
                <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/descuentos"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Descuentos"/>
                <meta property="og:description" content="Por unirte al #AllianzXLY Tour, ¡benefíciate de los mejores descuentos!"/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/Header.png"></meta>
            </Helmet>
            <Header/>



                <div className="inner-page-banner">
                    <img src="./images/Header.png" className="img-fluid" alt=""/>
                </div>


                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title section-title-blue section-title-normal">
                                <h2 className="color-dark">Por unirte al #AllianzXLY Tour, ¡benefíciate de los mejores descuentos!</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="promo-img">
                                    <img src="./images/Promociones-1.png" alt="promo-1"/>
                                </div>
                                <div className="content-title fix-padding">
                                    <h3>Llévate hasta un 40% de descuento en nuestros seguros</h3>
                                </div>
                                <div className="content-desc fix-padding">
                                    <p>Al formar parte del #AllianzXLY Tour, además de vivir una experiencia irrepetible, también podrás acceder a descuentos y beneficios exclusivos si contratas alguno de nuestros seguros.</p>
                                    <p>Disfruta de la tranquilidad de tener todas las garantías de la aseguradora líder mundial con descuentos exclusivos:</p>
                                    <p>
                                    Allianz Auto hasta un 40% de dto.
                                    <br/>
                                    Allianz Moto hasta un 40% de dto.
                                    <br/>
                                    Allianz Vida hasta un 35% de dto.
                                    <br/>
                                    Allianz Hogar hasta un 20% de dto.
                                    </p>
                                </div>
                                <div className="btn-post text-center fix-padding">
                                    <a href="https://www.allianz.es/campaigns/allianz-xuan-lan.html" target="_blank" rel="noreferrer" className="btn btn-blue"><span>Infórmate</span></a>
                                </div>
                                <div className="content-desc fix-padding">
                                    <p>Tu seguro de coche, moto, vida y hogar al precio que necesitas y con las mejores coberturas.</p>
                                    <p>¡Contrata tu seguro con Allianz y consigue siempre el mejor asesoramiento!</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="promo-img">
                                    <img src="./images/Promociones-2.png" alt="promo-1"/>
                                </div>
                                <div className="content-title fix-padding">
                                    <h3>Practica yoga y meditación con el Studio de Xuan Lan Yoga</h3>
                                </div>
                                <div className="content-desc fix-padding">
                                    <p>XLY Studio de Xuan Lan Yoga es la plataforma de clases de yoga y meditación online en español para practicar yoga dónde y cuándo quieras.</p>
                                    <p>Ahora puedes formar parte de XLY Studio y disfrutar de la plataforma con esta oferta especial.</p>
                                    <p>
                                    Únete a XLY Studio por
                                    <br/>
                                    9,99 € al mes durante 2 meses
                                    <br/>
                                    en vez de 14,99 €
                                    <br/>
                                    <br/>
                                    con el código:
                                    <br/>
                                    XLY2MALLIANZ
                                    </p>
                                </div>
                                <div className="btn-post text-center fix-padding">
                                    <a href=" https://studio.xuanlanyoga.com/orders/customer_info?o=25252&utm_source=landing&utm_medium=web&utm_campaign=allianz-tour&utm_content=promocion2m" target="_blank" rel="noreferrer" className="btn btn-blue"><span>Canjear descuento</span></a>
                                </div>
                                <div className="content-desc fix-padding">
                                    <p>Aprovecha esta oferta y suscríbete ahora a la plataforma más completa de yoga y meditación.</p>
                                    <p>¡Nos vemos en la esterilla con Xuan Lan!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
            </>
        )
    }

}


export default Promociones;