import React, { Component } from 'react';
import {HashLink as NavLink } from 'react-router-hash-link';
import { Link, animateScroll as scroll } from "react-scroll";
import { NavLink as ReactLink } from 'react-router-dom';
import $ from 'jquery';
import '../../css/style.css';



class Header extends Component {
    state = {
       isMobMenuOn:""
    }
   
    // hideMenu(){
        
    //         $('.navbar-collapse').collapse('hide');
        
    // }ramand295@gmail.com
    componentDidMount(){
        window.onscroll = function() {
            var screensize= $( window ).width();
            if(screensize>=768){
                if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                    $("header").css("padding","5px 0px 5px 0px");
                } else {
                    $("header").css("padding","32px 0px 40px 0px");
                }
            }
        };


    }
    // scrollFunction =()=> {
    //     if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    //         document.getElementById("navbar").style.padding = "30px 10px";
    //         document.getElementById("logo").style.fontSize = "25px";
    //     } else {
    //         document.getElementById("navbar").style.padding = "80px 10px";
    //         document.getElementById("logo").style.fontSize = "35px";
    //     }
    // }
    render() {
        return (
            <>

                <header>

                    <div className="container container-large">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-12">
                                <nav className="navbar navbar-expand-lg navbar-light">

                                    <ReactLink className="navbar-brand logo" to=""><img src="./images/allianz-positivo-1-1@2x.svg" className="img-fluid" alt="" /></ReactLink>

                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
 
                                            <img src="./images/toggle-icon.svg" className="menu-icon toggle-icon"/>
                                            <img src="./images/close-icon.svg" className="menu-icon close-icon"/>
                                        </button>

                                        <div className={`collapse navbar-collapse ${this.state.isMobMenuOn}`}id="navbarSupportedContent">
                                            <ul className="navbar-nav">
                                                <li><NavLink activeClassName="active" activeStyle={{ color: '#013F87' }}spy={true} smooth={true} offset={0} duration={300} aria-current="page" to="/#yogaTour" data-toggle="collapse" data-target=".navbar-collapse.in">Yoga Tour</NavLink></li>
                                                <li><NavLink activeClassName="active" spy={true} smooth={true} offset={0} duration={300} aria-current="page" to="/#destinos">Destinos</NavLink></li>
                                                <li><ReactLink activeClassName="active" to="sorteo">Sorteo</ReactLink></li>
                                                <li><ReactLink activeClassName="active" to="informacion">Información</ReactLink></li>
                                                <li><ReactLink activeClassName="active" to="inscripcion">Inscripción</ReactLink></li>
                                                <li><ReactLink activeClassName="active" to="descuentos">Descuentos</ReactLink></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>








                    </header>



            </>
        )
    }

}

export default Header;