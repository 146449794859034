import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Link as ReactLink } from 'react-router-dom';
import '../css/modal-video.css';
import ModalVideo from 'react-modal-video';
import Zoom from 'react-medium-image-zoom'
import {Helmet} from "react-helmet";
import 'react-medium-image-zoom/dist/styles.css'
// import bannerLogo from '../images/logo-banner-2.png'; Example of image used


import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Sevilla extends Component {

    state = {
        isOpen: false,
        isOpenBig: false
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    openModal = () => {
        this.setState({isOpen: true})
      }
    openModalBig = () => {
        this.setState({isOpenBig: true})
      }
    render() {
       
        return (

            <>
            
                            {/* <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/sevilla"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Sevilla"/>
                <meta property="og:description" content="En esta parada queremos invitarte a sentirte parte de una comunidad que  comparte una energía positiva con la práctica de yoga."/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/sevilla-header.png"></meta> */}
            <Helmet>

                <title>Allianz Xuan Lan Yoga Tour | Seville</title>
                <meta name="description" content="At this stop we want to invite you to feel part of a community that shares positive energy with the practice of yoga." />
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/sevilla/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Seville" />
                <meta property="og:description" content="At this stop we want to invite you to feel part of a community that shares positive energy with the practice of yoga." />
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/sevilla-header.png" />
                <meta property="twitter:domain" content="allianzxuanlanyogatour.com" />
                <meta property="twitter:url" content="https://www.allianzxuanlanyogatour.com/sevilla/" />
                <meta name="twitter:title" content="Allianz Xuan Lan Yoga Tour | Seville" />
                <meta name="twitter:description" content="At this stop we want to invite you to feel part of a community that shares positive energy with the practice of yoga." />
                <meta name="twitter:image" content="https://www.allianzxuanlanyogatour.com/images/sevilla-header.png"></meta>
            </Helmet>
            <Header/>
                <div className="inner-page-banner">
                    <img src="./images/sevilla-header.png" className="img-fluid" alt=""/>
                </div>


                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title">
                                <h2><b>Sevilla</b>
                                    <br/>
                                <span className="title-left-space color-dark">la armonía con la comunidad</span></h2> 
                                
                                <p className="text-center">
                                La Comunidad es un grupo de personas unidas por un centro de interés, una causa y unos valores, la comunidad no tiene fronteras físicas. Sentirse parte de una comunidad es un sentimiento fuerte, no estás solo, compartes algo con otras personas, das y recibes. Sabemos el bienestar y la alegría que sentimos cuando damos sin esperar nada a cambio, cuando contribuimos generosamente a un grupo y ayudamos a seres que lo necesitan. Cuando cultivar la compasión es más fuerte que el ego, no hay límites.
                                <br/>
                                <br/>
                                Comunidad implica no pedir por uno mismo sino por el conjunto, y si lo pensamos en grande, la tierra es una gran comunidad de seres humanos, animales y plantas que comparten el planeta.
                                <br/>
                                <br/>
                                El 26 de marzo, en la tercera parada del Allianz del Allianz Xuan Lan Yoga Tour, unimos nuestras energías creando una gran comunidad: un sentimiento en común.
                                <br/>
                                <br/>
                                ¡Descubre en detalle el #AllianzXLY Sevilla! 
                                {/* <br/>
                                <br/>
                                En esta parada queremos invitarte a sentirte parte de una comunidad que  comparte una energía positiva con la práctica de yoga observando con  atención tus  acciones, pensamientos y palabras para contribuir a la felicidad y libertad de todos.  */}
                                </p>
                                <br/>
                                <br/>
                               
                            </div>
                        </div>
                        <div className="col-lg-12 order-lg-1" style={{display:"none"}}>
                                <div className="btn-post" style={{textAlign:"center"}}>
                                    <a href="https://www.allianzxuanlanyogatour.com/inscripcion" className="btn btn-blue"><span className="text-light">Inscribirme</span></a>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="about-top-section inner-top-section pt-0 madrid-big-video">
                    <div className="container hover-cursor" onClick={this.openModalBig}>
                        <img src="./images/sevilla-video.png" alt="madrid video"/>
                    </div>
                </div>


                <div className="content-section content-img-left inr-content-section inr-content-section-1">
                    <div className="container">
                        <div className="section-title text-center mb-5">
                            <h3>Vive la experiencia desde dónde tú quieras<br/></h3>
                        </div>
                        <div className="row align-items-center inr-content-outer mt-0">
                            <div className="col-lg-6">
                                <div className="content-left" style={{paddingRight:"0px"}}>
                                <div className="row">
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-1.jpg" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-2.jpg" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-3.jpg" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-4.jpg" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-5.jpg" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-6.jpg" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-7.jpg" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-8.jpg" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/sevilla-9.jpg" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                </div>
                                    {/* <div className="content-img content-img-madrid">
                                        <img src="./images/meeu-roller-left.png" className="img-fluid pic" alt=""/>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right">
                                    <div className="content-text mt-0">
                                        <div className="content-title content-title-sevilla">
                                            <h3 className="color-blue"><br/>Plaza España de Sevilla</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>Con la Plaza España de Sevilla como escenario, más de 300 personas se unieron para conectar con el presente, nuestro entorno, mente y cuerpo.<br/>
                                            Un momento único que ahora puedes volver a vivir desde donde tú quieras. </p>
                                        </div>
                                        <div className="btn-post">
                                            <a href="https://www.youtube.com/watch?v=ljuvEqD4HGc" target="_blank" rel="noreferrer" className="btn btn-blue"><span className="text-light">#AllianzXLY Sevilla</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-section content-img-right inr-content-section inr-content-section-2">
                    <div className="container">
                        <div className="section-title text-center">
                            <h3>Invitado especial</h3>
                        </div>
                        <div className="row align-items-center inr-content-outer mt-0">
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="content-left">
                                    <div className="content-text">
                                        <div className="content-title">
                                            <h3>Mario Alonso Puig</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>Médico y licenciado en Harvard University Medical School, ha dedicado gran parte de su vida al estudio de la Inteligencia Humana y la psiconeurobiología.<br/>
                                            Lleva más de 20 años impartiendo cursos y conferencias, referente internacional en liderazgo, trabajo en equipo, gestión del cambio, salud, bienestar, felicidad, comunicación, creatividad e innovación.<br/>
                                            Mario junto a Xuan Lan nos ayudan a descubrir el concepto de comunidad desde un punto de vista diferente. ¡No te pierdas la entrevista completa! 
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0">
                                <div className="content-right hover-cursor" onClick={this.openModal}>
                                    <div className="content-img content-img-sevilla">
                                        <img src="./images/sevilla-right-small.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="btn-content text-center">
                            <ReactLink to="inscripcion" className="btn btn-blue"><span>Inscribirme</span></ReactLink>
                        </div>
                        <div className="small-text text-center custom-width">
                            <p><a href="https://consaludmental.org/" target="_blank"> Con la compra de tu entrada colaboras con la Confederación Salud Mental España y además, entras en el sorteo de un pase Meet&Greet presencial para conocer a Xuan Lan el día del evento.</a>
                            </p>
                        </div> */}
                    </div>
                    <div className="mt-5" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">
                                    {/* <div className="title-section text-center">
                                        <div className="section-title section-title-madrid">
                                            <p style={{color:"#000"}}>¿Sabías que la luz y los colores influyen constantemente en nuestra manera de actuar y de sentir? Para dar respuesta a esta y muchas otra preguntas, Xuan Lan entrevista a David del Rosario, científico e investigador especialista en Neurociencias. ¡No te lo puedes perder!</p>
                                        </div>
                                    </div> */}

                                    <div className="btn-post text-center">
                                        <a href="https://www.youtube.com/watch?v=0DexmEK8x84" rel="noreferrer" target="_blank" className="btn btn-blue"><span>Entrevista completa</span></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                {/* <div className="post-section" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">

                                    <div className="title-section text-center">
                                        <div className="section-title">
                                            <h2>¿Quieres vivir la experiencia en directo desde donde tú quieras?</h2>
                                            <p>¡Te proponemos una experiencia mágica online! <br/>
                                            Queremos que nos acompañes en este viaje del bienestar y formes parte del Allianz Xuan Lan Yoga tour estés donde estés. <br/>
                                            ¡Únete a nosotros en esta gran aventura!</p>
                                        </div>
                                    </div>

                                    <div className="btn-post text-center mb-5">
                                        <ReactLink to="asistir-online" className="btn btn-blue"><span>Registrarme online</span></ReactLink>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="about-top-section inner-top-section pt-0 madrid-big-video">
                    <div className="container hover-cursor" onClick={this.openModalBig}>
                        <img src="./images/barcelona-big-img.png" alt="madrid video"/>
                    </div>
                </div> */}
                {/* <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title">
                                <h2 style={{marginLeft:"1px"}} className="text-center color-dark">El evento</h2>
                                <p className="text-center">
                                En esta primera parada del #AllianzXLY Madrid descubriremos juntos cómo la armonía de la luz puede guiarnos en nuestro camino hacia el bienestar.
                                    <br/>
                                    <br/>
                                Empezaremos aprendiendo junto a Xuan Lan, en una entrevista con David del Rosario, investigador en neurociencias, a entender nuestra mente desde una perspectiva diferente. Parte de esta entrevista se retransmitirá antes de la masterclass, tanto en el evento presencial como durante el streaming, y se facilitará la entrevista completa a todos los participantes vía mail.
                                    <br/>
                                    <br/>
                                Seguiremos nuestro camino con una masterclass de yoga, una experiencia única y sensorial en donde la armonía de la luz y los colores nos guiarán durante toda la jornada.
                                    <br/>
                                    <br/>
                                Pero esto no termina aquí, muchas más sorpresas estarán esperándote en Madrid. ¿Te lo vas a perder?
                                </p>
                            
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* <div className="content-section content-img-left inr-content-section inr-content-section-1">
                    <div className="container">
                        <div className="section-title text-center mb-5">
                            <h3>Lugar y fecha del evento<br/></h3>
                        </div>
                        <div className="row align-items-center inr-content-outer">
                            <div className="col-lg-6">
                                <div className="content-left">
                                    <div className="content-img">
                                        <img src="./images/sevilla-left.png" className="img-fluid pic" alt="" style={{height:"auto"}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right">
                                    <div className="content-text" style={{marginTop:"0px"}}>
                                        <div className="content-title">
                                            <h3 className="color-blue">Plaza de España de Sevilla
                                            <br/>26 de marzo | Hora: 11:30h</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>Disfruta de una clase de yoga en la plaza más representativa e histórica de España. El punto de encuentro donde el arte y la historia se unen creando un espacio único en torno a la comunidad. </p>
                                        </div>
                                        <div className="content-icon-section">
                                            <p><img src="./images/map-location-pin-1-1@2x.svg" className="img-fluid" alt=""/> Av. Isabel la Católica, 41004 Sevilla</p>
                                             <p><img src="./images/subway-1@2x.svg" className="img-fluid" alt=""/> L3 Estación Drassanes</p> 
                                        </div>
                                        <div className="btn-post">
                                            <a href="https://www.allianzxuanlanyogatour.com/inscripcion" className="btn btn-blue"><span className="text-light">Inscribirme</span></a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="offset-1 col-10">
                            <div className="content-desc mt-5">
                                <p className="text-center">Cualquier novedad o cambio te mantendremos informad@ a través de este canal de comunicación y en las redes sociales @allianz_es y @xuanlanyoga.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* <div className="content-section content-img-right inr-content-section inr-content-section-2">
                    <div className="container">
                        <div className="section-title text-center">
                            <h3>Invitado especial</h3>
                        </div>
                        <div className="row align-items-center inr-content-outer">
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="content-left">
                                    <div className="content-text">
                                        <div className="content-title">
                                            <h3>Mario Alonso Puig </h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>Médico y licenciado en Harvard University Medical School, ha dedicado gran parte de su vida al estudio de la Inteligencia Humana y la psiconeurobiología.<br/>
                                            Lleva más de 20 años impartiendo cursos y conferencias, referente internacional en liderazgo, trabajo en equipo, gestión del cambio, salud, bienestar, felicidad, comunicación, creatividad e innovación.<br/>
                                            Estáte atento@ a nuestras redes sociales. Próximamente abriremos convocatoria a la inscripción del #AllianzXLY Sevilla.</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1">
                                <div className="content-right hover-cursor" onClick={this.openModal}>
                                    <div className="content-img">
                                        <img style={{height:"auto"}} src="./images/sevilla-right.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div className="btn-content text-center">
                            <ReactLink to="inscripcion" className="btn btn-blue"><span>Inscribirme</span></ReactLink>
                        </div>
                        <div className="small-text text-center custom-width">
                            <p><a href="https://consaludmental.org/" target="_blank"> Con la compra de tu entrada colaboras con la Confederación Salud Mental España y además, entras en el sorteo de un pase Meet&Greet presencial para conocer a Xuan Lan el día del evento.</a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-5" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">
                                    <div className="title-section text-center">
                                        <div className="section-title section-title-madrid">
                                            <p>¿Sabías que posiblemente el universo en el que vivimos se creó a partir de un sonido y vibración? Nuestra invitada The Wong Janice, violonchelista experta en la disciplina de sound healing, una técnica de sanación ancestral, nos mostrará junto a Xuan Lan los beneficios de este arte que ha ayudado a miles de personas transmitiendo el poder tiene la música. ¡No te lo puedes perder!</p>
                                        </div>
                                    </div>

                                    <div className="btn-post text-center">
                                        <a href="https://www.youtube.com/watch?v=J59LEIY2SpY&t=1s" rel="noreferrer" target="_blank" className="btn btn-blue"><span>Entrevista completa</span></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div> */} 
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='IYQNcb6roP0' onClose={() => this.setState({isOpen: false})} autoplay={true}/>
                <ModalVideo channel='youtube' isOpen={this.state.isOpenBig} videoId='ljuvEqD4HGc' onClose={() => this.setState({isOpenBig: false})} autoplay={true}/>
            <Footer/>
            </>
        )
    }

}




export default Sevilla;