import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Link as ReactLink } from 'react-router-dom';
// import bannerLogo from '../images/logo-banner-2.png'; Example of image used
import {Helmet} from "react-helmet";

import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Inscripcion extends Component {

    state = {
        
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    render() {
       
        return (
            <>
            <Helmet>
            <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/inscripcion"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Inscripción"/>
                <meta property="og:description" content="Vive una experiencia sensorial para conectar tu cuerpo y mente"/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/inscripcion-header.png"/>
            </Helmet>
            <Header/>



                <div className="inner-page-banner inner-page-banner-news">
                    <img src="./images/inscripcion-header.png" className="img-fluid" alt=""/>
                </div>


                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title section-title-blue section-title-normal">
                                <h2><b>Inscripción</b></h2>
                                 {/* <h4>Próximamente: Ibiza</h4> */}
                                <div className="row">
                                    <div className="col-12">
                                        <p className="width-80 mt-0">
                                        ¡Muchas gracias a todxs por formar parte del Allianz XLY Ibiza!
                                        </p>
                                    </div>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="btn-post text-center mb-5">
                    <ReactLink to="asistir-online" className="btn btn-blue"><span>Registrarme online</span></ReactLink>
                </div> */}
                <div className="profile-card-section">
                    <div className="container container-small">
                        <div className="row">
                            <div className="col-lg-5 d-md-block d-none">
                                <div className="profile-card-left">
                                    <div className="profile-card-left-inner">
                                        <div className="profile-card-img">
                                            <img src="./images/location-ibiza.png" className="img-fluid" alt=""/>
                                            {/* <h2>Ibiza</h2> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="profile-card-right">
                                    <div className="profile-card-right-inner">
                                        <div className="profile-card-title">
                                            <h2>Ibiza</h2>
                                        </div>
                                        {/* <div className="profile-card-content">
                                            <p>12 de diciembre, 2021
                                            <br/>
                                            Museo Marítimo de Barcelona
                                                <br/>
                                                Av. de les Drassanes s/n 08001 Barcelona
                                                <br/>
                                                L3 Estación Drassanes 
                                               </p>
                                        </div> */}
                                        <div className="profile-card-btn-outer">
                                            <div className=  "row">
                                                <div className="col-lg-8 col-md-6">
                                                    <div className="profile-card-btn-left">
                                                        {/* <a href="https://www.elcorteingles.es/entradas/channels/xuan-lan-yoga-tour/performance/performance-0000088Ih0000088hn0000085L4-xuanlanyogatour-channel" className="btn btn-blue " target="_blank" rel="noreferrer"><span>Comprar entradas</span></a> */}
                                                        <a href="https://www.elcorteingles.es/entradas/channels/xuan-lan-yoga-tour" className="btn btn-blue btn-disabled" target="_blank" rel="noreferrer"><span>Comprar entradas</span></a> 
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="profile-card-btn-right">
                                                        {/* <ReactLink to="asistir-online" className="btn btn-link"><span>Asistir online</span></ReactLink> */}
                                                        <ReactLink to="asistir-online" className="btn btn-link grey-btn link-disabled"><span>Asistir online</span></ReactLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="small-text">
                                        <p>La participación en el AllianzXLY implica que las imágenes y videos recopilados durante el evento se emplearán para difundirse en los canales oficiales de Allianz y Xuan Lan.
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="about-top-section inner-top-section">
                    <div className="container">
                        {/* <div className="title-section text-center">
                            <div className="section-title section-title-blue section-title-normal">
                                <h4>Información COVID</h4>
                                <div className="profile-card-content profile-card-content-incription-bottom">
                                    <p>Para acceder al recinto, tendrás que mostrar a la entrada tu certificado de vacunación (pauta vacunal completa) o una prueba de antígenos negativa. En el caso de no tener tu pauta vacunal completa, escríbenos a info@allianzxuanlanyogatour.es para poder ayudarte.</p>
                                </div>
                                <br/>
                            </div>
                        </div> */}
                         <div className="offset-1 col-10">
                            <div className="content-desc mt-5">
                                <p className="text-center">Cualquier novedad o cambio te mantendremos informad@ a través de este canal de comunicación y en las redes sociales @allianz_es y @xuanlanyoga.</p>
                            </div>
                        </div>
                    </div>
                </div>






            <Footer/>
            </>
        )
    }

}

export default Inscripcion;