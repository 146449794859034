import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Link as ReactLink } from 'react-router-dom';
import '../css/modal-video.css';
import ModalVideo from 'react-modal-video';
import Zoom from 'react-medium-image-zoom'
import {Helmet} from "react-helmet";
import 'react-medium-image-zoom/dist/styles.css'
// import bannerLogo from '../images/logo-banner-2.png'; Example of image used


import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Madrid extends Component {

    state = {
        isOpen: false,
        isOpenBig: false
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    openModal = () => {
        this.setState({isOpen: true})
      }
    openModalBig = () => {
        this.setState({isOpenBig: true})
      }
    render() {
       
        return (
            <>
            <Helmet>
            <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/madrid"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Madrid"/>
                <meta property="og:description" content="El 7 de noviembre, en la primera parada del Allianz Xuan Lan Yoga Tour, vivimos un sueño, una experiencia de luz y color recorriendo cada uno de los 7 chakras."/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/madrid-header.png"/>
            </Helmet>
            <Header/>
                <div className="inner-page-banner">
                    <img src="./images/madrid-header.png" className="img-fluid" alt=""/>
                </div>


                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title">
                                <h2><b>Madrid</b>
                                    <br/>
                                <span className="title-left-space color-dark">la armonía de la luz</span></h2>  
                                <p className="text-center">
                                La naturaleza desde siempre nos ha mostrado cómo afectan los colores en nuestras emociones y sentimientos. No reaccionamos igual ante un cielo azul que despertando en un día gris. Los colores pueden hacernos sentir enérgicos o relajados, favorecen sensaciones térmicas de frío o de calor, y también nos hacen percibir orden o desorden.
                                    <br/>
                                    <br/>
                                El 7 de noviembre, en la primera parada del Allianz Xuan Lan Yoga Tour, vivimos un sueño, una experiencia de luz y color recorriendo cada uno de los 7 chakras. El camino hacia la armonía llenó de luz el MEEU Rolling en Madrid y miles de hogares desde donde compartimos un Om colectivo.
                                    <br/>
                                    <br/>
                                ¡Descubre en detalle el #AllianzXLY Madrid!
                                    <br/>
                                    <br/>
                                </p>
                                <br/>
                                <br/>
                               
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="post-section" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">

                                    <div className="title-section text-center">
                                        <div className="section-title">
                                            <h2>¿Quieres vivir la experiencia en directo desde donde tú quieras?</h2>
                                            <p>¡Te proponemos una experiencia mágica online! <br/>
                                            Queremos que nos acompañes en este viaje del bienestar y formes parte del Allianz Xuan Lan Yoga tour estés donde estés. <br/>
                                            ¡Únete a nosotros en esta gran aventura!</p>
                                        </div>
                                    </div>

                                    <div className="btn-post text-center mb-5">
                                        <ReactLink to="asistir-online" className="btn btn-blue"><span>Registrarme online</span></ReactLink>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="about-top-section inner-top-section pt-0 madrid-big-video">
                    <div className="container hover-cursor" onClick={this.openModalBig}>
                        <img src="./images/madrid-video.png" alt="madrid video"/>
                    </div>
                </div>
                {/* <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title">
                                <h2 style={{marginLeft:"1px"}} className="text-center color-dark">El evento</h2>
                                <p className="text-center">
                                En esta primera parada del #AllianzXLY Madrid descubriremos juntos cómo la armonía de la luz puede guiarnos en nuestro camino hacia el bienestar.
                                    <br/>
                                    <br/>
                                Empezaremos aprendiendo junto a Xuan Lan, en una entrevista con David del Rosario, investigador en neurociencias, a entender nuestra mente desde una perspectiva diferente. Parte de esta entrevista se retransmitirá antes de la masterclass, tanto en el evento presencial como durante el streaming, y se facilitará la entrevista completa a todos los participantes vía mail.
                                    <br/>
                                    <br/>
                                Seguiremos nuestro camino con una masterclass de yoga, una experiencia única y sensorial en donde la armonía de la luz y los colores nos guiarán durante toda la jornada.
                                    <br/>
                                    <br/>
                                Pero esto no termina aquí, muchas más sorpresas estarán esperándote en Madrid. ¿Te lo vas a perder?
                                </p>
                            
                            </div>
                        </div>
                    </div>
                </div> */}


                <div className="content-section content-img-left inr-content-section inr-content-section-1">
                    <div className="container">
                        <div className="section-title text-center mb-5">
                            <h3>Vive la experiencia desde dónde tú quieras<br/></h3>
                        </div>
                        <div className="row align-items-center inr-content-outer mt-0">
                            <div className="col-lg-6">
                                <div className="content-left" style={{paddingRight:"0px"}}>
                                <div className="row">
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/madrid-1.png" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/madrid-2.png" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/madrid-3.png" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/madrid-4.png" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/madrid-5.png" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/madrid-6.png" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                </div>
                                    {/* <div className="content-img content-img-madrid">
                                        <img src="./images/meeu-roller-left.png" className="img-fluid pic" alt=""/>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right">
                                    <div className="content-text mt-0">
                                        <div className="content-title content-title-madrid">
                                            <h3 className="color-blue"><br/>MEEU Roller Chamartín</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>El camino hacia la armonía llenó de luz el MEEU Rolling en Madrid y miles de hogares. Fue un momento único. Te invitamos a probar el poder sanador de la luz y de los colores en esta primera parada del Allianz Xuan Lan Yoga Tour en Madrid.</p>
                                        </div>
                                        <div className="btn-post">
                                            <a href="https://bit.ly/allianzxly_streaming" target="_blank" rel="noreferrer" className="btn btn-blue"><span className="text-light">#AllianzXLY Madrid</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="content-section content-img-right inr-content-section inr-content-section-2">
                    <div className="container">
                        <div className="section-title text-center">
                            <h3>Invitado especial</h3>
                        </div>
                        <div className="row align-items-center inr-content-outer">
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="content-left">
                                    <div className="content-text">
                                        <div className="content-title">
                                            <h3>David del Rosario</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>David del Rosario es científico e investigador, músico, cineasta y escritor. Su pasión por los procesos de la vida le ha impulsado a proponer nuevos modelos para explicar la mente humana. Estudió Ingeniería Técnica en Telecomunicaciones en la Universidad de Alicante y MD en Ingeniería Biomédica en la Universidad de Barcelona</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0">
                                <div className="content-right hover-cursor" onClick={this.openModal}>
                                    <div className="content-img">
                                        <img src="./images/David_del_Rosario-Conferencia21.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="btn-content text-center">
                            <ReactLink to="inscripcion" className="btn btn-blue"><span>Inscribirme</span></ReactLink>
                        </div>
                        <div className="small-text text-center custom-width">
                            <p><a href="https://consaludmental.org/" target="_blank"> Con la compra de tu entrada colaboras con la Confederación Salud Mental España y además, entras en el sorteo de un pase Meet&Greet presencial para conocer a Xuan Lan el día del evento.</a>
                            </p>
                        </div> */}
                    </div>
                    <div className="mt-5" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">
                                    <div className="title-section text-center">
                                        <div className="section-title section-title-madrid">
                                            <p style={{color:"#000"}}>¿Sabías que la luz y los colores influyen constantemente en nuestra manera de actuar y de sentir? Para dar respuesta a esta y muchas otra preguntas, Xuan Lan entrevista a David del Rosario, científico e investigador especialista en Neurociencias. ¡No te lo puedes perder!</p>
                                        </div>
                                    </div>

                                    <div className="btn-post text-center">
                                        <a href="https://bit.ly/entrevista_madrid" rel="noreferrer" target="_blank" className="btn btn-blue"><span>Entrevista completa</span></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>



                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='Jd-kvD-WOPU' onClose={() => this.setState({isOpen: false})} autoplay={true}/>
                <ModalVideo channel='youtube' isOpen={this.state.isOpenBig} videoId='XTVuQwxJqMQ' onClose={() => this.setState({isOpenBig: false})} autoplay={true}/>
            <Footer/>
            </>
        )
    }

}




export default Madrid;