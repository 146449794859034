import './App.css';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './components/Home/Home';
import Madrid from './components/Madrid/Madrid';
import Barcelona from './components/Barcelona/Barcelona';
import Sevilla from './components/Sevilla/Sevilla';
import Sorteo from './components/Sorteo/Sorteo';
import Inscripcion from './components/Inscripcion/Inscripcion';
import Informacion from './components/Informacion/Informacion';
import AsistirOnline from './components/AsistirOnline/AsistirOnline';
import Cookies from './components/Cookies/Cookies';
import BasesLegales from './components/BasesLegales/BasesLegales';
import Promociones from './components/Promociones/Promociones';
import Ibiza from './components/Ibiza/Ibiza';

function App() {
  return (
    <Router>
      <Switch> 
        <Route exact path="/" name="Home" component={Home} />
        <Route exact path="/madrid" name="Madrid" component={Madrid} />
        <Route exact path="/barcelona" name="Barcelona" component={Barcelona} />
        <Route exact path="/sevilla" name="Sevilla" component={Sevilla} />
        <Route exact path="/sorteo" name="sorteo" component={Sorteo} />
        <Route exact path="/inscripcion" name="Inscripcion" component={Inscripcion} />
        <Route exact path="/informacion" name="Informacion" component={Informacion} />
        <Route exact path="/asistir-online" name="AsistirOnline" component={AsistirOnline} />
        <Route exact path="/cookies" name="Cookies" component={Cookies} />
        <Route exact path="/bases-legales" name="BasesLegales" component={BasesLegales} />
        <Route exact path="/descuentos" name="Promociones" component={Promociones} />
        <Route exact path="/ibiza" name="Ibiza" component={Ibiza} />
        <Route exact path="/ibzia" name="Ibiza" component={Ibiza} />
      </Switch>
    </Router>
  );
}

export default App;
