import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Link as ReactLink } from 'react-router-dom';
// import bannerLogo from '../images/logo-banner-2.png'; Example of image used
import {Helmet} from "react-helmet";

import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Informacion extends Component {

    state = {
        
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    render() {
       
        return (
            <>
            <Helmet>
                <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/informacion"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Información"/>
                <meta property="og:description" content="Información general sobre el evento"/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/inscripcion-header.png"/>
            </Helmet>
            <Header/>
                <div className="inner-page-banner inner-page-banner-news">
                    <img src="./images/inscripcion-header.png" className="img-fluid" alt=""/>
                </div>


                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title section-title-blue section-title-normal">
                                <h2><b>Evento presencial Ibiza</b></h2>
                                {/* <h2><b>Evento presencial Barcelona</b></h2> */}
                                {/* <p>Estate atent@ a nuestras redes sociales. Próximamente abriremos convocatoria a la inscripción de la <br/>próxima parada del tour</p> */}
                                <div className="faq-section">
                    <div className="container">
                        <div className="faq-inner">

                            <div className="acc-outer">

                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="heading1">
                                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">¿Dónde y cuándo puedo recoger mi tote bag?</button>
                                        </div> 
                                        <div id="collapse1" className="collapse show" aria-labelledby="heading1" data-parent="#accordion">
                                            <div className="card-body">
                                                <p>
                                                Podrás recoger tu totebag a la entrada del evento en Aiyanna. Allí tendrás a tu disposición vestuarios para poder cambiarte. Es importante que vengas con antelación para facilitar la entrega de totebags a tod@s l@s participantes.
                                                <br/> 
                                                <br/>
                                                <br/>
                                                </p>
                                                <img src="./images/Captura-de-pantalla-2021-11-11-a-las.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading2">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">¿Dónde y a qué hora tengo que estar para el evento? </button>
                                        </div>
                                        <div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#accordion">
                                            <div className="card-body">
                                                <p>28 de mayo en Aynna Ibiza</p>
                                                <p>Apertura de puertas: 18:30 </p>
                                                <p>Inicio del evento: 19:30</p>
                                                <p>El evento comienza a las 19:30h es importante que llegues entre esas horas para garantizar una entrada escalonada al recinto. </p>
                                                <br/>
                                                <img src="./images/information-Ibiza.png" alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="heading3">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">¿Tengo que llevar material? </button>
                                        </div>
                                        <div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#accordion">
                                            <div className="card-body"> 
                                                <p>No, allí te daremos una esterilla que estará colocada con el número de tu entrada. Al finalizar la clase podrás llevártela a casa para seguir disfrutando la experiencia. </p>
                                                <p>Podrás dejar tus pertenencias en un guardarropa, nuestro equipo se encargará de guardarlas durante todo el evento. Es importante que al espacio donde se dará la clase entres solo con tu teléfono para poder disfrutar de la masterclass sin objetos de por medio.</p>
                                                <br/>
                                                <img src="./images/ALLIANZ XUANLANYOGA-017-1.png" alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="card">
                                        <div className="card-header" id="heading4">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">Recomendaciones:</button>
                                        </div>
                                        <div id="collapse8" className="collapse" aria-labelledby="heading4" data-parent="#accordion">
                                            <div className="card-body">
                                                <p>
                                                Te recomendamos que vengas con el mínimo número de bultos posible dado que no hay vestuarios. Además, queremos que vengas con la totebag que te daremos en El corte Inglés para ser sostenibles y reducir el uso de plásticos.
                                                <br/><br/>

                                                Debido a las altas temperaturas que se preveen para la fecha, sería ideal que usaras protección solar para evitar quemaduras al estar al aire libre.
                                                <br/><br/>

                                                Por último; antes de venir acuérdate de rellenar el tarjetón de la intención y traerlo para poder colgarlo en el árbol de las intenciones.
                                                <br/><br/>

                                                Recomendamos el uso de mascarilla durante el evento.

                                                </p>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="card">
                                        <div className="card-header" id="heading4">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">Información COVID:</button>
                                        </div>
                                        <div id="collapse9" className="collapse" aria-labelledby="heading4" data-parent="#accordion">
                                            <div className="card-body">
                                                <p>
                                                Para acceder, tendrás que mostrar tu certificado de vacunación (pauta vacunal completa) o una prueba de antígenos negativa.
                                                <br/>
                                                Con el objetivo de garantizar la distancia de seguridad, las esterillas estarán colocadas con un espacio de 1,5 m entre sí.
                                                <br/>
                                                Recomendamos el uso de mascarilla durante el evento.
                                                <br/>
                                                Si aún no tienes tu pauta vacunal completa...
                                                <br/>
                                                Antes de entrar, te ofrecemos la posibilidad de hacerte un test de antígenos para garantizar el cumplimiento de las medidas COVID-19. En este caso, tienes que escribirnos a <a href="mailto:info@allianzxuanlanyogatour.com">info@allianzxuanlanyogatour.com</a> antes del  de marzo y te informaremos del proceso.
                                                <br/>

                                                </p>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title section-title-blue section-title-normal">
                                <h2><b>Evento online Ibiza</b></h2>
                                <div className="faq-section">
                    <div className="container">
                        <div className="faq-inner">

                            <div className="acc-outer">

                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="heading5">
                                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">SAVE THE DATE </button>
                                        </div> 
                                        <div id="collapse5" className="collapse show" aria-labelledby="heading5" data-parent="#accordion">
                                            <div className="card-body">
                                                <p>28 de mayo, 2022 
                                                    <br/>
                                                    19:30h
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="heading6">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">¿A qué hora tengo que conectarme?</button>
                                        </div>
                                        <div id="collapse6" className="collapse" aria-labelledby="heading6" data-parent="#accordion">
                                            <div className="card-body">  
                                                <p>La retransmisión de la masterclass comienza a las 19:20.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="heading6">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">¿Qué necesitas para vivir la experiencia online?</button>
                                        </div>
                                        <div id="collapse7" className="collapse" aria-labelledby="heading6" data-parent="#accordion">
                                            <div className="card-body">  
                                            <p>La ropa… </p>
                                                <p>Ponte ropa cómoda con la que te sientas 100% a gusto. Nosotros te recomendamos prendas ajustadas para que te sientas más libre en tu práctica.</p>
                                                <p>El ambiente...</p>
                                                <p>Crea un ambiente relajado con una luz tenue, enciende tus velas favoritas y disfruta de tu momento. Despeja la zona en la que estés y si puedes, conéctate desde tu televisión para vivir una experiencia con los 5 sentidos.</p>
                                                <p>Un matt de yoga...</p>
                                                <p>El accesorio estrella. Es importante que tengas una esterilla a mano para seguir la masterclass.</p>
                                                <p>Y por último…</p>
                                                <p>¡Disfruta de la masterclass y comparte tu experiencia con el hashtag #AllianzXLY!</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
            </>
        )
    }

}

export default Informacion;