import React, { Component } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import {HashLink as NavLink } from 'react-router-hash-link';
import { Link as ReactLink } from 'react-router-dom';
import '../../css/style.css';



class Footer extends Component {
    state = {
       
    }
    render() {
        return (
            <>
                <footer className="footer-section">

                    <div className="footer-cta text-center">
                        <div className="container">
                            <div className="footer-cta-inner">
                                <div className="footer-cta-title">
                                    <h2><strong>Namasté</strong></h2>
                                </div>
                                <div className="footer-subtitle">
                                    {/* <p>Déjanos apoyarte</p> */}
                                    <p>Allianz, Déjanos Apoyarte</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-quote">
                        <div className="container">
                            <div className="footer-quote-inner">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-lg-3 col-md-4">
                                        <div className="footer-quote-left">
                                            <div className="footer-quote-img mb-5">
                                            <a href="https://consaludmental.org" target="_blank" rel="noreferrer"> 
                                                <img src="./images/conf_saludmental_espana1.svg" className="img-fluid" alt=""/>
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="footer-quote-right">
                                            <div className="footer-quote-text mb-5">
                                                <p>A través de esta iniciativa colaboramos con la Confederación de Salud Mental de España.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="footer-quote-text my-5 text-center">
                                            <p>Con la colaboración de</p>
                                        </div>
                                    </div>  
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="footer-quote-img mb-2 footer-logo-left">
                                                <img src="./images/LogoSolgar.svg" className="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div className="footer-quote-img mb-2 footer-logo-right">
                                                <img src="./images/ECI.svg" className="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="footer-box-section">
                        <div className="container">
                            <div className="footer-box-outer">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-6">
                                        <div className="footer-box">
                                            <div className="footer-box-inner">
                                                <div className="footer-logo">
                                                    
                                                    <img src="./images/allianz-positivo-1-1@2x.svg" className="img-fluid" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-6">
                                        <div className="footer-box">
                                            <div className="footer-box-inner">
                                                <p>Contacto</p>

                                                <p><a href="tel:917230206">91 723 02 06</a>
                                                    <br/>
                                                    <a href="mailto:info@allianzxuanlanyogatour.com" className="d-md-block d-none">info@allianzxuanlanyogatour.com</a>
                                                    <a href="mailto:info@allianzxuanlanyogatour.com" className="d-md-none d-block">info@allianzxuanlan yogatour.com</a></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="footer-box">
                                            <div className="footer-box-inner">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="footer-menu">
                                                            <ul>
                                                                <li><ReactLink to="/">Home</ReactLink></li>
                                                                <li><NavLink to="/#destinos">Destinos</NavLink></li>
                                                                <li><ReactLink to="sorteo">Sorteo</ReactLink></li>
                                                                <li><ReactLink to="/cookies">Política de cookies</ReactLink></li>
                                                                <li><ReactLink to="/bases-legales">Bases legales</ReactLink></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="footer-menu">
                                                            <ul>
                                                                <li><ReactLink to="inscripcion">Inscripción</ReactLink></li>
                                                                {/* <li><ReactLink to="madrid">Madrid</ReactLink></li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-12">
                                    <div className="footer-box footer-box-social">
                                            <div className="footer-box-inner">
                                                <p>Síguenos:</p>
                                                <div className="footer-social">
                                                    <ul>
                                                        <li className="footer-social-name"><a href="#">Allianz España:</a></li>
                                                        <li><a href="https://www.instagram.com/allianz_es/" target="_blank" rel="noreferrer"><img src="./images/social--media-instagram--square@2x.svg" className="img-fluid" alt=""/></a></li>
                                                        <li><a href="https://www.youtube.com/c/AllianzSeguros" target="_blank" rel="noreferrer"><img src="./images/youtube-square.svg" className="img-fluid" alt=""/></a></li>
                                                        <li><a href="https://www.facebook.com/allianz.seguros.es/" target="_blank" rel="noreferrer"><img src="./images/social--media-facebook@2x.svg" className="img-fluid" alt=""/></a></li>
                                                        <li><a href="https://twitter.com/Allianz_es/" target="_blank" rel="noreferrer"><img src="./images/social--media-twitter--square@2x-svg.svg" className="img-fluid" alt=""/></a></li>
                                                    </ul>
                                                </div>
                                                <div className="footer-social mt-3">
                                                    <ul>
                                                        <li className="footer-social-name"><a href="#">Xuan Lan Yoga:</a></li>
                                                        <li><a href="https://www.instagram.com/xuanlanyoga/" target="_blank" rel="noreferrer"><img src="./images/social--media-instagram--square@2x.svg" className="img-fluid" alt=""/></a></li>
                                                        <li><a href="https://www.youtube.com/c/XuanLanYoga" target="_blank" rel="noreferrer"><img src="./images/youtube-square.svg" className="img-fluid" alt=""/></a></li>
                                                        <li><a href="https://www.facebook.com/xuanlanyoga" target="_blank" rel="noreferrer"><img src="./images/social--media-facebook@2x.svg" className="img-fluid" alt=""/></a></li>
                                                        <li><a href="https://twitter.com/xuanlanyoga" target="_blank" rel="noreferrer"><img src="./images/social--media-twitter--square@2x-svg.svg" className="img-fluid" alt=""/></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="footer-copy">
                                <p>Allianz © All rights reserved</p>
                            </div>
                        </div>
                    </div>








                </footer>
            </>
        )
    }

}


/*$(document).ready(function(){

    $("header ul.navbar-nav li a").click(function(){
        if($('.navbar-collapse').hasClass('show')){
            $('.navbar-collapse').removeClass("show");
            $('.navbar-toggler').addClass("collapsed");
            $('.navbar-toggler').attr("aria-expanded","false");
        }else{
            $('.navbar-collapse').addClass("hide");
        }
    });

});*/


export default Footer;