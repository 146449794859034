import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Widget } from '@typeform/embed-react'
import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'
import {Helmet} from "react-helmet";


import $ from 'jquery';

class Cookies extends Component {

    state = {

    };
    componentDidMount() {

    window.scrollTo(0,0);

    const script = document.createElement("script");
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.async = true;
    document.body.appendChild(script);
}
    render() {

    return (
        <>
            <Helmet>
                <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
            </Helmet>
            <Header/>



            <div className="inner-page-banner">
                <img src="./images/Header.png" className="img-fluid" alt=""/>
            </div>


            <div className="about-top-section inner-top-section">
                <div className="container">
                    <div className="title-section text-center">
                        <div className="section-title section-title-blue section-title-normal">
                            <h2><b>Política de cookies</b></h2>
                        </div>
                    </div>
                </div>
            </div>
                <div className="container">
                    <div className="title-section text-center">
                        <div className="section-title section-title-blue section-title-normal">
                                {/* <!-- OneTrust Cookies List start --> */}
                                <div id="ot-sdk-cookie-policy"></div>
                                {/* <!-- OneTrust Cookies List end --> */}
                        </div>
                    </div>
                </div>
            






            <Footer/>
</>
)
}

}

export default Cookies;