import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Widget } from '@typeform/embed-react'
import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'
import {Helmet} from "react-helmet";


import $ from 'jquery';

class AsistirOnline extends Component {

    state = {
        
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    render() {
       
        return (
            <>
            <Helmet>
                <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/asistir-online"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Asiste online"/>
                <meta property="og:description" content="Vive la experiencia online que te permitirá conectar con tu alma"/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/inscripcion-header.png"></meta>
            </Helmet>
            <Header/>



                <div className="inner-page-banner">
                <img src="./images/inscripcion-header.png" className="img-fluid" alt=""/>
                </div>

                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title section-title-blue section-title-normal">
                             
                                <h2><b>Vive la experiencia online que te permitirá conectar con tu alma</b></h2>
                                <p>Un viaje sensorial lleno de emociones y sentimientos que te permitirá conectar con tu interior.
                                    <br/>
                                    <br/>
                                Te invitamos a vivir una experiencia única a través de la pantalla creando un ambiente mágico y lleno de energía. 
                                    <br/>
                                    <br/>
                                    <span className="color-blue1">
                                        <b>¡Además, tendrás la oportunidad de participar en el sorteo de 10 pases meet & greet online para conectarte con Xuan Lan el día del evento!
                                        </b>
                                    </span>
                                    <br/>
                                    <br/>
                                    ¡Completa los siguientes datos para que puedas conectarte de forma gratuita al evento en directo y comienza junto a nosotros esta gran aventura! 
                                    <br/>
                                    <span className="color-blue1">¡En solo 3 pasos!</span>
                                </p>
                            </div>
                            <div className="row my-5">
                                <div className="offset-md-2 col-md-8 col-12">
                                    <div className="row">
                                    <div className="col-lg-4">
                                        <div className="numbers-outer">
                                            <div class="first circle">1</div>
                                            <p className="color-blue">Nombre y apellidos</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="numbers-outer">
                                        <div class="first circle">2</div>
                                            <p className="color-blue">Introduce tu email</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="numbers-outer">
                                        <div class="first circle">3</div>
                                            <p className="color-blue">Acepta las condiciones</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-section">
                    <div className="container text-center">
                        <Widget id="DT8CrePN" style={{ width: '50%',height:"600px" }} transitiveSearchParams={["utm_source", "utm_medium", "utm_campaign", "utm_content"]} className="my-form" />
                    </div>
                </div>
            <Footer/>
            </>
        )
    }

}

export default AsistirOnline;