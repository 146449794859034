import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link as ReactLink } from 'react-router-dom';
import '../css/style.css';
import { Widget } from '@typeform/embed-react'
import {Helmet} from "react-helmet";
import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Sorteo extends Component {

    state = {
        
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    render() {
       
        return (
            <>
            <Helmet>
                <title>Allianz Xuan Lan Yoga Tour | Sorteo Ibiza</title>
                <meta name="description" content="¿Quieres conseguir 1 entrada doble, con desplazamiento y alojamiento incluidos, para asistir al evento en Ibiza? Solo tienes que contestar a las siguientes preguntas y entrarás directamente en el sorteo de una entrada para ti y quien tú elijas."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/sorteo"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Sorteo Ibiza"/>
                <meta property="og:description" content="Disfruta de una experiencia única conectando con la naturaleza y los sentidos en la isla de Ibiza."/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/images/Header.png"></meta>
            </Helmet>
            <Header/>



                <div className="inner-page-banner">
                    <img src="./images/Header.png" className="img-fluid" alt=""/>
                </div>

                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center"> 
                            <div className="section-title section-title-blue">
                                <h2><b>Sorteo | Ibiza</b>
                                    <br/>
                                <span className="title-left-space">la armonía con la naturaleza</span></h2>
                                <p className="text-center">¡Sorteo finalizado! ¡Muchas gracias a todxs por participar! <br/>
                                ¡Enhorabuena a la ganadora: Paula Cabado Brea!
                                </p>
                                {/* <p className="text-center"></p>

                                <p className="text-center">¿Quieres conseguir 1 entrada doble, con desplazamiento y alojamiento incluidos, para asistir al evento en Ibiza? Solo tienes que contestar a las siguientes preguntas y entrarás directamente en el sorteo de una entrada para ti y quien tú elijas.
                                <br/>
                                Consulta las bases legales del sorteo
                                <br/>
                                ¡Te deseamos mucha suerte!
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="form-section">
                    <div className="container text-center">
                        <Widget id="ElNAR7PZ" transitiveSearchParams={["utm_source", "utm_medium", "utm_campaign", "utm_content"]} style={{ width: '50%' }} className="my-form" />
                    </div>
                </div> */}

            <Footer/>
            </>
        )
    }

}


export default Sorteo;