import React, { Component } from 'react';
import { Link as ReactLink} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, animateScroll as scroll } from "react-scroll";
import '../css/style.css';
import '../css/modal-video.css';
import ModalVideo from 'react-modal-video'
import {Helmet} from "react-helmet";
import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Home extends Component {

    state = {
        cookiesPopup:sessionStorage.getItem("isCookies"),
        showPopup:false,
        isOpen: false
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

        if(sessionStorage.getItem("isCookies") === null){
            this.setState({
                cookiesPopup:"show"
            })
        }
        setTimeout(() => {
            this.setState({showPopup: true});
            console.log("HIDE COOKIES");
          }, 5000)
    } 
    hideCookies =() => {
        sessionStorage.setItem("isCookies","hide")
        this.setState({
            cookiesPopup:"hide"
        })
    }
    hidePopup =() => {
        this.setState({
            showPopup:false
        })
    }
    openModal = () => {
        this.setState({isOpen: true})
      }
    render() {

        let cookiesPopupView = null, homePopup = null;
        if(this.state.cookiesPopup===null || this.state.cookiesPopup==="show"){
            cookiesPopupView = ( <div className="cookies-popup-section">
            <div className="container container-big">
                <div className="cookies-inner">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="cookies-popup-left">
                                <div className="cookies-content">
                                    <p>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="cookies-popup-right">
                                <div className="cookies-popup-btn-outer">
                                    <a href="#" className="cookies-link">Cookie Setting</a>
                                    <a onClick={this.hideCookies} className="cookies-btn">Accept All Cookies</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
        
        }
        if(this.state.showPopup===null || this.state.showPopup===true){
            homePopup = ( 
                <div className="popup-section custom-popup">
                    <div className="popup-inner">
                        <div className="popup-close">
                            <a onClick={this.hidePopup} className="hover-cursor"><img src="./images/Frame28.svg" classNameName="img-fluid pic" alt=""/></a>
                        </div>
                        <div className="popup-content">
                            <div className="popup-title desktop">
                                <h2>¡La cuenta atrás ha<br/> comenzado!
                                </h2>
                            </div>
                            <div className="popup-title mobile">
                                <h2>¡La cuenta atrás ha comenzado!
                                </h2>
                            </div>
                            <div className="popup-desc ">
                                <p className="text-20">Tenemos muchas ganas de compartir con tod@s vosotr@s la tercera parada del #AllianzXLY en Sevilla.<br/>
                                ¡Nos vemos el próximo 26 de marzo a las 11:30h en la Plaza España de Sevilla!
                                <br/><br/>
                                Cualquier novedad o cambio te mantendremos informad@ a través de este canal de comunicación y en las redes sociales @allianz_es y @xuanlanyoga.
                                </p>
                            </div>
                            <div className="popup-btn top-90" style={{display:"none"}}>
                                <ReactLink to="asistir-online" className="btn btn-white"><span>Registrarme online</span></ReactLink>
                            </div>
                        </div>
                    </div>
                </div>
        );
        }
        return (
            <>
            <Helmet>
                <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour"/>
                <meta property="og:description" content="Un tour con destino a cuatro ciudades para estar en armonía con uno mismo y con los demás."/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/static/media/bg-slider.3c71fbe0.png"></meta>
            </Helmet>
            <Header/>
            {/* {homePopup} */}
            {/* {cookiesPopupView} */}

                <div className="banner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-inner">
                                    <div className="banner-title banner-title-home">
                                        <h1>Allianz - Xuan Lan <br/> <span>Yoga Tour</span></h1>
                                    </div>
                                    {/* <div className="btn-banner">
                                        <ReactLink to="/inscripcion" className="btn btn-white"><span>Inscribirme</span></ReactLink>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="about-top-section home-section" id="yogaTour">
                    <div className="container">
                        <div className="title-section title-section-small text-center">
                            <div className="section-title">
                                <h2>Vive una <b>experiencia sensorial</b>
                                    <br/>
                                <i className="title-left-space">para conectar tu cuerpo y mente</i></h2>
                                <p className="text-center f-32">Un tour con destino a cuatro ciudades para estar en armonía con uno mismo y con los demás.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="content-section content-img-left">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content-left hover-cursor" onClick={this.openModal}>
                                    <div className="content-img" >
                                        <img src="./images/VideoXuan.png" className="img-fluid pic" alt=""/>
                                        <div className="icon-play">
                                            <img src="./images/ant-design-play-circle-filled@2x.svg" className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right">
                                    <div className="content-text">
                                        <div className="content-title">
                                            <h3>Unidos por tu bienestar</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>En Allianz estamos comprometidos con el bienestar de tod@s y apostamos no solo por la salud física sino también por el equilibrio emocional, por eso nos hemos unido a la profesora de yoga y experta en bienestar @xuanlanyoga, un referente mundial en este campo.
                                            </p>
                                            <p>
                                            <p>
                                            El tour te permitirá disfrutar de una masterclass 3.0 de yoga y meditación, una gira en donde cada ciudad está vinculada a una temática que te aportará las claves necesarias para conectar tu cuerpo y mente.
                                            </p>
                                            Un evento único donde miles de personas en el mundo compartiremos un Om colectivo y haremos circular la energía entre todos. Descubre todas las fechas e invitados en cada una de las paradas y únete al #AllianzXLY Tour.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="post-section" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">

                                    <div className="title-section text-center">
                                        <div className="section-title">
                                            <h2>4 destinos para conectar con uno mismo
                                                <br/>
                                            <b>y vivir la experiencia yogui más exclusiva</b></h2>
                                        </div>
                                    </div>

                                    <div className="post-box-outer">
                                        <div className="row">

                                            <div className="col-lg-3 col-md-4">
                                                <div className="post-box active">
                                                    <div className="post-box-inner">
                                                        <div className="post-box-img">
                                                            <img src="./images/location-madrid.png" className="img-fluid" alt=""/>
                                                        </div>
                                                        <ReactLink to="madrid">
                                                        
                                                        <div className="post-box-content">
                                                            <div className="original-text">
                                                                <h4>Madrid</h4>
                                                            </div>
                                                            <div className="hover-text">
                                                                <h4>Ver evento</h4>
                                                            </div>
                                                        </div>
                                                        </ReactLink>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-md-4">
                                                <div className="post-box active">
                                                    <div className="post-box-inner">
                                                        <div className="post-box-img">
                                                            <img src="./images/location-barcelona.png" className="img-fluid" alt=""/>
                                                        </div>
                                                        <ReactLink to="barcelona">
                                                        
                                                        <div className="post-box-content">
                                                            <div className="original-text">
                                                                <h4>Barcelona</h4>
                                                            </div>
                                                            <div className="hover-text">
                                                                <h4>Ver evento</h4>
                                                            </div>
                                                        </div>
                                                        </ReactLink>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-md-4">
                                                <div className="post-box active">
                                                    <div className="post-box-inner">
                                                        <div className="post-box-img">
                                                            <img src="./images/location-sevilla.png" className="img-fluid" alt=""/>
                                                        </div>
                                                        <ReactLink to="sevilla">
                                                        
                                                        <div className="post-box-content">
                                                            <div className="original-text">
                                                                <h4>Sevilla</h4>
                                                            </div>
                                                            <div className="hover-text">
                                                                <h4>Ver evento</h4>
                                                            </div>
                                                        </div>
                                                        </ReactLink>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-md-4">
                                                <div className="post-box active">
                                                    <div className="post-box-inner">
                                                        <div className="post-box-img">
                                                            <img src="./images/location-ibzia.png" className="img-fluid" alt=""/>
                                                        </div>
                                                        <ReactLink to="ibiza">
                                                        
                                                        <div className="post-box-content">
                                                            <div className="original-text">
                                                                <h4>Ibiza</h4>
                                                            </div>
                                                            <div className="hover-text">
                                                                <h4>Ver evento</h4>
                                                            </div>
                                                        </div>
                                                        </ReactLink>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-3 col-md-4">
                                                <div className="post-box">
                                                    <div className="post-box-inner">
                                                        <div className="post-box-img">
                                                            <img src="./images/recurso-2@2x.png" className="img-fluid" alt=""/>
                                                        </div>
                                                        <div className="post-box-content">
                                                            <div className="original-text">
                                                                <h4>Ibiza</h4>
                                                            </div>
                                                            <div className="hover-text">
                                                                <h4>Próximamente</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}


                                        </div>
                                    </div>

                                    <div className="btn-post text-center" style={{textAlign:'center'}}>
                                        <ReactLink to="inscripcion" className="btn btn-blue"><span>Descubre más</span></ReactLink>
                                    </div>

                                   
                                    {/* <div className="small-text text-center custom-width">
                                        <p><a href="https://consaludmental.org/" target="_blanl" rel="norefferer"> Con la compra de tu entrada colaboras con la Confederación Salud Mental España y además, entras en el sorteo de un pase Meet&Greet presencial para conocer a Xuan Lan el día del evento.</a>
                                        </p>
                                    </div> */}

                                    {/* <div className="content-section content-img-right">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 order-lg-0 order-1">
                                                    <div className="content-left">
                                                        <div className="content-text">
                                                            <div className="content-title">
                                                                <h3>Asiste online</h3>
                                                            </div>
                                                            <div className="content-desc">
                                                                <p>En caso de no poder asistir presencialmente al evento, podrás vivir la experiencia en directo desde donde tu quieras, y formar parte del <span>Allianz Xuan Lan Yoga Tour</span>. Queremos que nos acompañes en este viaje de bienestar disfrutando de una masterclass de yoga estés donde estés.  </p>
                                                            </div>
                                                            <div className="btn-content">
                                                                <ReactLink to="asistir-online" className="btn btn-blue"><span>Registrarme Online</span></ReactLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 order-lg-1 order-0">
                                                    <div className="content-right">
                                                        <div className="content-img">
                                                            <img src="./images/cabecera-4@1x.png" className="img-fluid pic" alt=""/>
                                                            <div className="icon-live">
                                                                <div className="live-circle"></div>
                                                                <div className="live-text">LIVE</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="post-section" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">

                                    {/* <div className="title-section text-center">
                                        <div className="section-title">
                                            <h2>¿Quieres vivir la experiencia en directo desde donde tú quieras?</h2>
                                            <p>¡Te proponemos una experiencia mágica online! <br/>
                                            Queremos que nos acompañes en este viaje del bienestar y formes parte del Allianz Xuan Lan Yoga tour estés donde estés. <br/>
                                            ¡Únete a nosotros en esta gran aventura!</p>
                                        </div>
                                    </div>

                                   

                                    <div className="btn-post text-center mb-5">
                                        <ReactLink to="asistir-online" className="btn btn-blue"><span>Registrarme online</span></ReactLink>
                                    </div>
                                    <div className="offset-1 col-10">
                                        <div className="content-desc mt-5">
                                            <p className="text-center color-blue1" style={{fontSize:'16px'}}>Y además, con tu inscripción online entras en el sorteo de 10 meet&greet virtuales para conectarte con Xuan Lan en directo el día del evento</p>
                                        </div>
                                    </div> */}
                                    <div className="content-section content-img-right pt-0">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 order-lg-0 order-1">
                                                    <div className="content-left padding-right-0">
                                                        <div className="content-text">
                                                            <div className="section-title">
                                                                <h2>
                                                                    Yoga con causa: <br/>
                                                                    <b>apoyando la salud mental</b>
                                                                </h2>
                                                            </div>
                                                            <div className="content-desc">
                                                                <p>Con el Allianz Xuan Lan Yoga Tour reforzamos nuestro firme propósito y compromiso de mejorar la vida de las personas, logrando un equilibrio físico y emocional. Es por ello que este proyecto colabora con la Confederación Salud Mental España destinando el importe íntegro de las entradas a la organización. <br/>Tú también puedes contribuir haciendo una donación a través de este <a href="https://consaludmental.org/colabora/donacion/" target="_blank" rel="noreferrer" className="color-blue">enlace</a></p>
                                                            </div>
                                                            {/* <div className="btn-content">
                                                                <ReactLink to="asistir-online" className="btn btn-blue"><span>Registrarme Online</span></ReactLink>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 order-lg-1 order-0">
                                                    <div className="content-right mt-5">
                                                        <div className="content-img">
                                                            <img src="./images/ALLIANZ-XUANLANYOGA.png" className="img-fluid pic" alt=""/>
                                                            {/* <div className="icon-live">
                                                                <div className="live-circle"></div>
                                                                <div className="live-text">LIVE</div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* <div className="popup-section popup-section-new">
                                        <div className="popup-inner">
                                            <div className="popup-close">
                                                
                                            </div>
                                            <div className="popup-content">
                                                <div className="popup-title">
                                                    <h2>Sorteo Ibiza</h2>
                                                </div>
                                                <div className="popup-desc">
                                                    <p>Disfruta de una experiencia única conectando con la naturaleza y los sentidos en la isla de Ibiza.</p>

                                                    <p>¿Quieres conseguir 1 entrada doble (desplazamiento y alojamiento incluidos) al evento de Ibiza? Solo tienes que contestar a las siguientes preguntas y entrarás directamente en el sorteo de una entrada para ti y quien tú elijas. 
                                                        <br/>
                                                        <ReactLink to="bases-legales">Consulta las bases legales del sorteo</ReactLink>
                                                        <span></span>
                                                        <br/>
                                                        <br/>
                                                    ¡Te deseamos mucha suerte!</p>
                                                </div>
                                                <div className="popup-btn_">
                                                    <ReactLink to="sorteo" className="btn btn-white"><span>Participar</span></ReactLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='q8baaB67VRY' onClose={() => this.setState({isOpen: false})} autoplay={true}/>
            <Footer/>
            </>
        )
    }

}


$(document).ready(function(){

    $("header ul.navbar-nav li a").click(function(){
        if($('.navbar-collapse').hasClass('show')){
            $('.navbar-collapse').removeClass("show");
            $('.navbar-toggler').addClass("collapsed");
            $('.navbar-toggler').attr("aria-expanded","false");
        }else{
    $('.navbar-collapse').addClass("hide");
    }
});

});

export default Home;