import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import '../css/style.css';
import { Link as ReactLink } from 'react-router-dom';
import '../css/modal-video.css';
import ModalVideo from 'react-modal-video';
import Zoom from 'react-medium-image-zoom'
import {Helmet} from "react-helmet";
import 'react-medium-image-zoom/dist/styles.css'
// import bannerLogo from '../images/logo-banner-2.png'; Example of image used


import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class Barcelona extends Component {

    state = {
        isOpen: false,
        isOpenBig: false
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    openModal = () => {
        this.setState({isOpen: true})
      }
    openModalBig = () => {
        this.setState({isOpenBig: true})
      }
    render() {
       
        return (
            <>
            <Helmet>
                <title>Allianz Xuan Lan Yoga Tour | Una experiencia única en favor de tu bienestar</title>
                <meta name="description" content="Allianz Xuan Lan Yoga Tour  es un tour con destino a cuatro ciudades para estar en armonía con unos mismo y con los demás. En cada una de estas paradas, los usuarios además de las masterclass, disfrutarán de una experiencia única en favor del bienestar. En cada una de estas paradas, los usuarios además de la mastarclass de bienestar."/>
                <meta property="og:url" content="https://www.allianzxuanlanyogatour.com/barcelona"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Allianz Xuan Lan Yoga Tour | Barcelona"/>
                <meta property="og:description" content="El 12 de diciembre, en la segunda parada del Allianz Xuan Lan Yoga Tour, vivimos un sueño, una experiencia donde la música y las vibraciones fluyeron durante nuestro encuentro."/>
                <meta property="og:image" content="https://www.allianzxuanlanyogatour.com/images/barcelona-header.png"></meta>
            </Helmet>
            <Header/>
                <div className="inner-page-banner">
                    <img src="./images/barcelona-header.png" className="img-fluid" alt=""/>
                </div>


                <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title">
                                <h2><b>Barcelona</b>
                                    <br/>
                                <span className="title-left-space color-dark">la armonía con la música</span></h2> 
                                
                                <p className="text-center">
                                Posiblemente el universo en el que vivimos se creó a partir de un sonido y vibración.
                                    <br/>
                                    <br/>
                                    Se sabe que los textos más antiguos de la literatura india, los vedas, fueron revelados oralmente a través de estrofas/mantras pronunciados y cantados con unos tonos de voz que al parecer despertaba el autoconocimiento espiritual en los sabios de aquel entonces.
                                    <br/>
                                    <br/>
                                    El oído es el primer sentido que desarrollamos en el útero de nuestra madre. Conectados por el cordón umbilical dentro del vientre ya oímos su voz, antes de respirar.
                                    <br/>
                                    <br/>
                                    Está demostrado que el sonido y sus vibraciones provocan emociones y estimulan ciertos sentimientos.
                                    <br/>
                                    <br/>
                                    El 12 de diciembre, en la segunda parada del Allianz Xuan Lan Yoga Tour, vivimos un sueño, una experiencia donde la música y las vibraciones fluyeron durante nuestro encuentro. 
                                    <br/>
                                    <br/>
                                    ¡Descubre en detalle el #AllianzXLY Barcelona! 
                                    <br/>
                                    <br/>
                                </p>
                                <br/>
                                <br/>
                               
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="post-section" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">

                                    <div className="title-section text-center">
                                        <div className="section-title">
                                            <h2>¿Quieres vivir la experiencia en directo desde donde tú quieras?</h2>
                                            <p>¡Te proponemos una experiencia mágica online! <br/>
                                            Queremos que nos acompañes en este viaje del bienestar y formes parte del Allianz Xuan Lan Yoga tour estés donde estés. <br/>
                                            ¡Únete a nosotros en esta gran aventura!</p>
                                        </div>
                                    </div>

                                    <div className="btn-post text-center mb-5">
                                        <ReactLink to="asistir-online" className="btn btn-blue"><span>Registrarme online</span></ReactLink>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="about-top-section inner-top-section pt-0 madrid-big-video">
                    <div className="container hover-cursor" onClick={this.openModalBig}>
                        <img src="./images/barcelona-big-img.png" alt="madrid video"/>
                    </div>
                </div>
                {/* <div className="about-top-section inner-top-section">
                    <div className="container">
                        <div className="title-section text-center">
                            <div className="section-title">
                                <h2 style={{marginLeft:"1px"}} className="text-center color-dark">El evento</h2>
                                <p className="text-center">
                                En esta primera parada del #AllianzXLY Madrid descubriremos juntos cómo la armonía de la luz puede guiarnos en nuestro camino hacia el bienestar.
                                    <br/>
                                    <br/>
                                Empezaremos aprendiendo junto a Xuan Lan, en una entrevista con David del Rosario, investigador en neurociencias, a entender nuestra mente desde una perspectiva diferente. Parte de esta entrevista se retransmitirá antes de la masterclass, tanto en el evento presencial como durante el streaming, y se facilitará la entrevista completa a todos los participantes vía mail.
                                    <br/>
                                    <br/>
                                Seguiremos nuestro camino con una masterclass de yoga, una experiencia única y sensorial en donde la armonía de la luz y los colores nos guiarán durante toda la jornada.
                                    <br/>
                                    <br/>
                                Pero esto no termina aquí, muchas más sorpresas estarán esperándote en Madrid. ¿Te lo vas a perder?
                                </p>
                            
                            </div>
                        </div>
                    </div>
                </div> */}


                <div className="content-section content-img-left inr-content-section inr-content-section-1">
                    <div className="container">
                        <div className="section-title text-center mb-5">
                            <h3>Vive la experiencia desde dónde tú quieras<br/></h3>
                        </div>
                        <div className="row align-items-center inr-content-outer mt-0">
                            <div className="col-lg-6">
                                <div className="content-left" style={{paddingRight:"0px"}}>
                                <div className="row">
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/barcelona-1.png" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/barcelona-2.png" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/barcelona-3.png" className="img-fluid pic mb-3" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/barcelona-4.png" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/barcelona-5.png" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                    <div className="col-4 px-2">
                                        <Zoom overlayBgColorEnd={"rgb(0 0 0 / 48%)"}>
                                            <img src="./images/barcelona-6.png" className="img-fluid pic mb-2" alt=""/>
                                        </Zoom>
                                    </div>
                                </div>
                                    {/* <div className="content-img content-img-madrid">
                                        <img src="./images/meeu-roller-left.png" className="img-fluid pic" alt=""/>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right">
                                    <div className="content-text mt-0">
                                        <div className="content-title content-title-madrid">
                                            <h3 className="color-blue"><br/>Museo Marítimo de Barcelona</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>El camino hacia la armonía llenó de buenas vibraciones el Museo Marítimo de Barcelona y miles de hogares. Fue un momento único que ahora puedes volver a vivir desde donde tú quieras.</p>
                                        </div>
                                        <div className="btn-post">
                                            <a href=" https://www.youtube.com/watch?v=9K-yx-nfGsA" target="_blank" rel="noreferrer" className="btn btn-blue"><span className="text-light">#AllianzXLY Barcelona</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="content-section content-img-right inr-content-section inr-content-section-2">
                    <div className="container">
                        <div className="section-title text-center">
                            <h3>Invitado especial</h3>
                        </div>
                        <div className="row align-items-center inr-content-outer">
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="content-left">
                                    <div className="content-text">
                                        <div className="content-title">
                                            <h3>The Wong Janice</h3>
                                        </div>
                                        <div className="content-desc">
                                            <p>¡The Wong Janice es una artista y violonchelista holandesa que ayuda a miles de personas como tú a sentirse más centradas y tranquilas a través de música ambiental de meditación con violonchelo.</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0">
                                <div className="content-right hover-cursor" onClick={this.openModal}>
                                    <div className="content-img">
                                        <img src="./images/barcelona-vid.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="btn-content text-center">
                            <ReactLink to="inscripcion" className="btn btn-blue"><span>Inscribirme</span></ReactLink>
                        </div>
                        <div className="small-text text-center custom-width">
                            <p><a href="https://consaludmental.org/" target="_blank"> Con la compra de tu entrada colaboras con la Confederación Salud Mental España y además, entras en el sorteo de un pase Meet&Greet presencial para conocer a Xuan Lan el día del evento.</a>
                            </p>
                        </div> */}
                    </div>
                    <div className="mt-5" id="destinos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-inner">
                                    <div className="title-section text-center">
                                        <div className="section-title section-title-madrid">
                                            <p>¿Sabías que posiblemente el universo en el que vivimos se creó a partir de un sonido y vibración? Nuestra invitada The Wong Janice, violonchelista experta en la disciplina de sound healing, una técnica de sanación ancestral, nos mostrará junto a Xuan Lan los beneficios de este arte que ha ayudado a miles de personas transmitiendo el poder tiene la música. ¡No te lo puedes perder!</p>
                                        </div>
                                    </div>

                                    <div className="btn-post text-center">
                                        <a href="https://www.youtube.com/watch?v=J59LEIY2SpY&t=1s" rel="noreferrer" target="_blank" className="btn btn-blue"><span>Entrevista completa</span></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='IRaaC9nAlxg' onClose={() => this.setState({isOpen: false})} autoplay={true}/>
                <ModalVideo channel='youtube' isOpen={this.state.isOpenBig} videoId='-gm8xP35dWU' onClose={() => this.setState({isOpenBig: false})} autoplay={true}/>
            <Footer/>
            </>
        )
    }

}




export default Barcelona;